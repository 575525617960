// Generated by Peggy 2.0.1.
//
// https://peggyjs.org/


    import * as glob from "./glob.js";

    function cleanText(text) {
        // replace \" with "
        return typeof text === "string" ? text.replaceAll('\\"', '"') : text;
    }

    function collapse(node) {
        // if a node has one child, return that child, if it has no children, return Nothing
        if (node.children.length === 0) {
            return { kind: "Nothing" };
        }
        else if (node.children.length === 1) {
            return node.children[0];
        }
        // if node is an AND or OR and has a child of the same kind, collapse it as well
        if (node.kind === "And" || node.kind === "Or") {
            for (let i = 0; i < node.children.length; i++) {
                const child = node.children[i];
                if (child.kind === node.kind) {
                    // remove this node and replace it with its children
                    node.children.splice(i, 1, ...child.children)
                    if (child.operatorLocations?.length > 0) {
                        if (!node.operatorLocations) {
                            node.operatorLocations = [];
                        }
                        node.operatorLocations.push(...child.operatorLocations);
                    }
                    // no need to process the new children
                    i += child.children.length - 1;
                }
            }
        }
        return node;
    }

    function makeConjunction(kind, children, location, operatorLocation) {
        const node = { kind, children, location }
        if (operatorLocation) {
            node.operatorLocations = [operatorLocation];
        }
        return collapse(node)
    }

    function supportsNumericalQualifierValues(qualifier) {
        if (qualifier.raw === "repo_id:") {
           return true
        }

        return {
           "Stars": true,
           "Forks": true,
           "Reactions": true,
           "Interactions": true,
        }[qualifier.qualifier] || false
    }

    function makeQualifier(qualifier) {
        if (qualifier.content === undefined) {
            qualifier.content = {kind: 'Nothing'}
        }

        const n = Number(qualifier.content.value);
        if (!isNaN(n) && supportsNumericalQualifierValues(qualifier)) {
            qualifier.content.value = n;
            qualifier.content.kind = "Number";
        }
        if (qualifier.qualifier === "Symbol" && qualifier.raw === "def:") {
            if (qualifier.content.value) {
                // escape regex characters
                qualifier.content.kind = "Regex";
                qualifier.content.value = `(^|\\b)${glob.escapeForRegexp(qualifier.content.value || "")}$`;
            }
        }
        if (qualifier.qualifier === "Path" && qualifier.content.kind === "Text") {
            // Consider parsing as a glob if it's a text query using the path qualifier
            let value = qualifier.content.value;
            if (glob.isPossibleGlobExpression(value)) {
                qualifier.content.kind = "Regex";
                qualifier.content.value = glob.convertGlobToRegex(value);
            }
        }
        return qualifier;
    }

    function makeText(value, location) {
        return { kind: "Text", value: cleanText(value), location };
    }

function peg$subclass(child, parent) {
  function C() { this.constructor = child; }
  C.prototype = parent.prototype;
  child.prototype = new C();
}

function peg$SyntaxError(message, expected, found, location) {
  var self = Error.call(this, message);
  // istanbul ignore next Check is a necessary evil to support older environments
  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(self, peg$SyntaxError.prototype);
  }
  self.expected = expected;
  self.found = found;
  self.location = location;
  self.name = "SyntaxError";
  return self;
}

peg$subclass(peg$SyntaxError, Error);

function peg$padEnd(str, targetLength, padString) {
  padString = padString || " ";
  if (str.length > targetLength) { return str; }
  targetLength -= str.length;
  padString += padString.repeat(targetLength);
  return str + padString.slice(0, targetLength);
}

peg$SyntaxError.prototype.format = function(sources) {
  var str = "Error: " + this.message;
  if (this.location) {
    var src = null;
    var k;
    for (k = 0; k < sources.length; k++) {
      if (sources[k].source === this.location.source) {
        src = sources[k].text.split(/\r\n|\n|\r/g);
        break;
      }
    }
    var s = this.location.start;
    var loc = this.location.source + ":" + s.line + ":" + s.column;
    if (src) {
      var e = this.location.end;
      var filler = peg$padEnd("", s.line.toString().length, ' ');
      var line = src[s.line - 1];
      var last = s.line === e.line ? e.column : line.length + 1;
      var hatLen = (last - s.column) || 1;
      str += "\n --> " + loc + "\n"
          + filler + " |\n"
          + s.line + " | " + line + "\n"
          + filler + " | " + peg$padEnd("", s.column - 1, ' ')
          + peg$padEnd("", hatLen, "^");
    } else {
      str += "\n at " + loc;
    }
  }
  return str;
};

peg$SyntaxError.buildMessage = function(expected, found) {
  var DESCRIBE_EXPECTATION_FNS = {
    literal: function(expectation) {
      return "\"" + literalEscape(expectation.text) + "\"";
    },

    class: function(expectation) {
      var escapedParts = expectation.parts.map(function(part) {
        return Array.isArray(part)
          ? classEscape(part[0]) + "-" + classEscape(part[1])
          : classEscape(part);
      });

      return "[" + (expectation.inverted ? "^" : "") + escapedParts.join("") + "]";
    },

    any: function() {
      return "any character";
    },

    end: function() {
      return "end of input";
    },

    other: function(expectation) {
      return expectation.description;
    }
  };

  function hex(ch) {
    return ch.charCodeAt(0).toString(16).toUpperCase();
  }

  function literalEscape(s) {
    return s
      .replace(/\\/g, "\\\\")
      .replace(/"/g,  "\\\"")
      .replace(/\0/g, "\\0")
      .replace(/\t/g, "\\t")
      .replace(/\n/g, "\\n")
      .replace(/\r/g, "\\r")
      .replace(/[\x00-\x0F]/g,          function(ch) { return "\\x0" + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return "\\x"  + hex(ch); });
  }

  function classEscape(s) {
    return s
      .replace(/\\/g, "\\\\")
      .replace(/\]/g, "\\]")
      .replace(/\^/g, "\\^")
      .replace(/-/g,  "\\-")
      .replace(/\0/g, "\\0")
      .replace(/\t/g, "\\t")
      .replace(/\n/g, "\\n")
      .replace(/\r/g, "\\r")
      .replace(/[\x00-\x0F]/g,          function(ch) { return "\\x0" + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return "\\x"  + hex(ch); });
  }

  function describeExpectation(expectation) {
    return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
  }

  function describeExpected(expected) {
    var descriptions = expected.map(describeExpectation);
    var i, j;

    descriptions.sort();

    if (descriptions.length > 0) {
      for (i = 1, j = 1; i < descriptions.length; i++) {
        if (descriptions[i - 1] !== descriptions[i]) {
          descriptions[j] = descriptions[i];
          j++;
        }
      }
      descriptions.length = j;
    }

    switch (descriptions.length) {
      case 1:
        return descriptions[0];

      case 2:
        return descriptions[0] + " or " + descriptions[1];

      default:
        return descriptions.slice(0, -1).join(", ")
          + ", or "
          + descriptions[descriptions.length - 1];
    }
  }

  function describeFound(found) {
    return found ? "\"" + literalEscape(found) + "\"" : "end of input";
  }

  return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
};

function peg$parse(input, options) {
  options = options !== undefined ? options : {};

  var peg$FAILED = {};
  var peg$source = options.grammarSource;

  var peg$startRuleFunctions = { Search: peg$parseSearch };
  var peg$startRuleFunction = peg$parseSearch;

  var peg$c0 = "(";
  var peg$c1 = ")";
  var peg$c2 = "AND";
  var peg$c3 = "OR";
  var peg$c4 = "NOT";
  var peg$c5 = "-";
  var peg$c6 = "lang:";
  var peg$c7 = "language:";
  var peg$c8 = "repo:";
  var peg$c9 = "repo_id:";
  var peg$c10 = "user:";
  var peg$c11 = "owner:";
  var peg$c12 = "org:";
  var peg$c13 = "symbol:";
  var peg$c14 = "def:";
  var peg$c15 = "is:";
  var peg$c16 = "saved:";
  var peg$c17 = "path:";
  var peg$c18 = "content:";
  var peg$c19 = "trait:";
  var peg$c20 = "type:";
  var peg$c21 = "in:";
  var peg$c22 = "sort:";
  var peg$c23 = "author:";
  var peg$c24 = "commenter:";
  var peg$c25 = "involves:";
  var peg$c26 = "created:";
  var peg$c27 = "updated:";
  var peg$c28 = "comments:";
  var peg$c29 = "category:";
  var peg$c30 = "answered-by:";
  var peg$c31 = "label:";
  var peg$c32 = "milestone:";
  var peg$c33 = "team:";
  var peg$c34 = "state:";
  var peg$c35 = "project:";
  var peg$c36 = "no:";
  var peg$c37 = "assignee:";
  var peg$c38 = "mentions:";
  var peg$c39 = "merged:";
  var peg$c40 = "closed:";
  var peg$c41 = "status:";
  var peg$c42 = "head:";
  var peg$c43 = "base:";
  var peg$c44 = "reactions:";
  var peg$c45 = "interactions:";
  var peg$c46 = "followers:";
  var peg$c47 = "review:";
  var peg$c48 = "archived:";
  var peg$c49 = "draft:";
  var peg$c50 = "mirror:";
  var peg$c51 = "template:";
  var peg$c52 = "linked:";
  var peg$c53 = "pushed:";
  var peg$c54 = "license:";
  var peg$c55 = "has:";
  var peg$c56 = "fullname:";
  var peg$c57 = "location:";
  var peg$c58 = "repositories:";
  var peg$c59 = "stars:";
  var peg$c60 = "forks:";
  var peg$c61 = "topic:";
  var peg$c62 = "sha:";
  var peg$c63 = "tree:";
  var peg$c64 = "reason:";
  var peg$c65 = "\"";
  var peg$c66 = "/";
  var peg$c67 = "\\/";
  var peg$c68 = "\\\"";

  var peg$r0 = /^[ \t\n\r]/;
  var peg$r1 = /^[0-9]/;

  var peg$e0 = peg$literalExpectation("(", false);
  var peg$e1 = peg$literalExpectation(")", false);
  var peg$e2 = peg$literalExpectation("AND", false);
  var peg$e3 = peg$literalExpectation("OR", false);
  var peg$e4 = peg$literalExpectation("NOT", false);
  var peg$e5 = peg$literalExpectation("-", false);
  var peg$e6 = peg$literalExpectation("lang:", true);
  var peg$e7 = peg$literalExpectation("language:", true);
  var peg$e8 = peg$literalExpectation("repo:", true);
  var peg$e9 = peg$literalExpectation("repo_id:", true);
  var peg$e10 = peg$literalExpectation("user:", true);
  var peg$e11 = peg$literalExpectation("owner:", true);
  var peg$e12 = peg$literalExpectation("org:", true);
  var peg$e13 = peg$literalExpectation("symbol:", true);
  var peg$e14 = peg$literalExpectation("def:", true);
  var peg$e15 = peg$literalExpectation("is:", true);
  var peg$e16 = peg$literalExpectation("saved:", true);
  var peg$e17 = peg$literalExpectation("path:", true);
  var peg$e18 = peg$literalExpectation("content:", true);
  var peg$e19 = peg$literalExpectation("trait:", true);
  var peg$e20 = peg$literalExpectation("type:", true);
  var peg$e21 = peg$literalExpectation("in:", true);
  var peg$e22 = peg$literalExpectation("sort:", true);
  var peg$e23 = peg$literalExpectation("author:", true);
  var peg$e24 = peg$literalExpectation("commenter:", true);
  var peg$e25 = peg$literalExpectation("involves:", true);
  var peg$e26 = peg$literalExpectation("created:", true);
  var peg$e27 = peg$literalExpectation("updated:", true);
  var peg$e28 = peg$literalExpectation("comments:", true);
  var peg$e29 = peg$literalExpectation("category:", true);
  var peg$e30 = peg$literalExpectation("answered-by:", true);
  var peg$e31 = peg$literalExpectation("label:", true);
  var peg$e32 = peg$literalExpectation("milestone:", true);
  var peg$e33 = peg$literalExpectation("team:", true);
  var peg$e34 = peg$literalExpectation("state:", true);
  var peg$e35 = peg$literalExpectation("project:", true);
  var peg$e36 = peg$literalExpectation("no:", true);
  var peg$e37 = peg$literalExpectation("assignee:", true);
  var peg$e38 = peg$literalExpectation("mentions:", true);
  var peg$e39 = peg$literalExpectation("merged:", true);
  var peg$e40 = peg$literalExpectation("closed:", true);
  var peg$e41 = peg$literalExpectation("status:", true);
  var peg$e42 = peg$literalExpectation("head:", true);
  var peg$e43 = peg$literalExpectation("base:", true);
  var peg$e44 = peg$literalExpectation("reactions:", true);
  var peg$e45 = peg$literalExpectation("interactions:", true);
  var peg$e46 = peg$literalExpectation("followers:", true);
  var peg$e47 = peg$literalExpectation("review:", true);
  var peg$e48 = peg$literalExpectation("archived:", true);
  var peg$e49 = peg$literalExpectation("draft:", true);
  var peg$e50 = peg$literalExpectation("mirror:", true);
  var peg$e51 = peg$literalExpectation("template:", true);
  var peg$e52 = peg$literalExpectation("linked:", true);
  var peg$e53 = peg$literalExpectation("pushed:", true);
  var peg$e54 = peg$literalExpectation("license:", true);
  var peg$e55 = peg$literalExpectation("has:", true);
  var peg$e56 = peg$literalExpectation("fullname:", true);
  var peg$e57 = peg$literalExpectation("location:", true);
  var peg$e58 = peg$literalExpectation("repositories:", true);
  var peg$e59 = peg$literalExpectation("stars:", true);
  var peg$e60 = peg$literalExpectation("forks:", true);
  var peg$e61 = peg$literalExpectation("topic:", true);
  var peg$e62 = peg$literalExpectation("sha:", true);
  var peg$e63 = peg$literalExpectation("tree:", true);
  var peg$e64 = peg$literalExpectation("reason:", true);
  var peg$e65 = peg$classExpectation([" ", "\t", "\n", "\r"], false, false);
  var peg$e66 = peg$anyExpectation();
  var peg$e67 = peg$literalExpectation("\"", false);
  var peg$e68 = peg$literalExpectation("/", false);
  var peg$e69 = peg$literalExpectation("\\/", false);
  var peg$e70 = peg$literalExpectation("\\\"", false);
  var peg$e71 = peg$classExpectation([["0", "9"]], false, false);

  var peg$f0 = function(head, tail) { return makeConjunction("And", head ? [head, ...tail] : [], range()) };
  var peg$f1 = function(head, tail) { return { kind: "Group", children: [makeConjunction("And", [head, ...tail], range())], location: range() } };
  var peg$f2 = function(left) { return { location: range() } };
  var peg$f3 = function(left, op, right) { return makeConjunction("And", [left, right], range(), op.location) };
  var peg$f4 = function(left) { return { location: range() } };
  var peg$f5 = function(left, op, right) { return makeConjunction("Or", [left, right], range(), op.location) };
  var peg$f6 = function() { return { location: range() } };
  var peg$f7 = function(op, exp) { return { kind: "Not", children: [exp], location: range(), operatorLocations: [op.location] } };
  var peg$f8 = function(left) { return { location: range() } };
  var peg$f9 = function(left, op, right) { return makeConjunction("And", [left, right], range(), op.location) };
  var peg$f10 = function(left) { return { location: range() } };
  var peg$f11 = function(left, op, right) { return makeConjunction("Or", [left, right], range(), op.location) };
  var peg$f12 = function() { return { location: range() } };
  var peg$f13 = function(op, exp) { return { kind: "Not", children: [exp], location: range(), operatorLocations: [op.location] } };
  var peg$f14 = function(qualifier) { const r = range(); return { kind: "Not", children: [qualifier], location: r, operatorLocations: [{ start: r.start, end: r.start + 1 }] } };
  var peg$f15 = function(raw) { return { qualifier: "Language", raw, location: range() } };
  var peg$f16 = function(raw) { return { qualifier: "Repo", raw, location: range() } };
  var peg$f17 = function(raw) { return { qualifier: "Org", raw, location: range() } };
  var peg$f18 = function(raw) { return { qualifier: "Symbol", raw, location: range() } };
  var peg$f19 = function(raw) { return { qualifier: "Is", raw, location: range() } };
  var peg$f20 = function(raw) { return { qualifier: "Saved", raw, location: range() } };
  var peg$f21 = function(raw) { return { qualifier: "Path", raw, location: range() } };
  var peg$f22 = function(raw) { return { qualifier: "Content", raw, location: range() } };
  var peg$f23 = function(raw) { return { qualifier: "Trait", raw, location: range() } };
  var peg$f24 = function(raw) { return { qualifier: "Type", raw, location: range() } };
  var peg$f25 = function(raw) { return { qualifier: "In", raw, location: range() } };
  var peg$f26 = function(raw) { return { qualifier: "Sort", raw, location: range() } };
  var peg$f27 = function(raw) { return { qualifier: "Author", raw, location: range() } };
  var peg$f28 = function(raw) { return { qualifier: "Commenter", raw, location: range() } };
  var peg$f29 = function(raw) { return { qualifier: "Involves", raw, location: range() } };
  var peg$f30 = function(raw) { return { qualifier: "Created", raw, location: range() } };
  var peg$f31 = function(raw) { return { qualifier: "Updated", raw, location: range() } };
  var peg$f32 = function(raw) { return { qualifier: "Comments", raw, location: range() } };
  var peg$f33 = function(raw) { return { qualifier: "Category", raw, location: range() } };
  var peg$f34 = function(raw) { return { qualifier: "AnsweredBy", raw, location: range() } };
  var peg$f35 = function(raw) { return { qualifier: "Label", raw, location: range() } };
  var peg$f36 = function(raw) { return { qualifier: "Milestone", raw, location: range() } };
  var peg$f37 = function(raw) { return { qualifier: "Team", raw, location: range() } };
  var peg$f38 = function(raw) { return { qualifier: "State", raw, location: range() } };
  var peg$f39 = function(raw) { return { qualifier: "Project", raw, location: range() } };
  var peg$f40 = function(raw) { return { qualifier: "No", raw, location: range() } };
  var peg$f41 = function(raw) { return { qualifier: "Assignee", raw, location: range() } };
  var peg$f42 = function(raw) { return { qualifier: "Mentions", raw, location: range() } };
  var peg$f43 = function(raw) { return { qualifier: "Merged", raw, location: range() } };
  var peg$f44 = function(raw) { return { qualifier: "Closed", raw, location: range() } };
  var peg$f45 = function(raw) { return { qualifier: "Status", raw, location: range() } };
  var peg$f46 = function(raw) { return { qualifier: "Head", raw, location: range() } };
  var peg$f47 = function(raw) { return { qualifier: "Base", raw, location: range() } };
  var peg$f48 = function(raw) { return { qualifier: "Reactions", raw, location: range() } };
  var peg$f49 = function(raw) { return { qualifier: "Interactions", raw, location: range() } };
  var peg$f50 = function(raw) { return { qualifier: "Followers", raw, location: range() } };
  var peg$f51 = function(raw) { return { qualifier: "Review", raw, location: range() } };
  var peg$f52 = function(raw) { return { qualifier: "Archived", raw, location: range() } };
  var peg$f53 = function(raw) { return { qualifier: "Draft", raw, location: range() } };
  var peg$f54 = function(raw) { return { qualifier: "Mirror", raw, location: range() } };
  var peg$f55 = function(raw) { return { qualifier: "Template", raw, location: range() } };
  var peg$f56 = function(raw) { return { qualifier: "Linked", raw, location: range() } };
  var peg$f57 = function(raw) { return { qualifier: "Pushed", raw, location: range() } };
  var peg$f58 = function(raw) { return { qualifier: "License", raw, location: range() } };
  var peg$f59 = function(raw) { return { qualifier: "Has", raw, location: range() } };
  var peg$f60 = function(raw) { return { qualifier: "FullName", raw, location: range() } };
  var peg$f61 = function(raw) { return { qualifier: "Location", raw, location: range() } };
  var peg$f62 = function(raw) { return { qualifier: "Repositories", raw, location: range() } };
  var peg$f63 = function(raw) { return { qualifier: "Stars", raw, location: range() } };
  var peg$f64 = function(raw) { return { qualifier: "Forks", raw, location: range() } };
  var peg$f65 = function(raw) { return { qualifier: "Topic", raw, location: range() } };
  var peg$f66 = function(raw) { return { qualifier: "Sha", raw, location: range() } };
  var peg$f67 = function(raw) { return { qualifier: "Tree", raw, location: range() } };
  var peg$f68 = function(raw) { return { qualifier: "Reason", raw, location: range() } };
  var peg$f69 = function(x) { return makeQualifier({ kind: "Qualifier", ...x[0], content: x[1] }) };
  var peg$f70 = function() { return makeText(text(), range()) };
  var peg$f71 = function() { return makeText(text(), range()) };
  var peg$f72 = function() { return makeText(text(), range()) };
  var peg$f73 = function(regex) { return { kind: "Regex", value: regex.map(r => r[1]).join(""), location: range() } };
  var peg$f74 = function(letters) { return { kind: "Text", value: cleanText(letters.join("")), location: range() } };
  var peg$f75 = function() { return { kind: "Number", value: Number(text()), location: range() } };
  var peg$currPos = 0;
  var peg$savedPos = 0;
  var peg$posDetailsCache = [{ line: 1, column: 1 }];
  var peg$maxFailPos = 0;
  var peg$maxFailExpected = [];
  var peg$silentFails = 0;

  var peg$result;

  if ("startRule" in options) {
    if (!(options.startRule in peg$startRuleFunctions)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
  }

  function text() {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function offset() {
    return peg$savedPos;
  }

  function range() {
    return {
      source: peg$source,
      start: peg$savedPos,
      end: peg$currPos
    };
  }

  function location() {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description, location) {
    location = location !== undefined
      ? location
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location
    );
  }

  function error(message, location) {
    location = location !== undefined
      ? location
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildSimpleError(message, location);
  }

  function peg$literalExpectation(text, ignoreCase) {
    return { type: "literal", text: text, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts, inverted, ignoreCase) {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation() {
    return { type: "any" };
  }

  function peg$endExpectation() {
    return { type: "end" };
  }

  function peg$otherExpectation(description) {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos) {
    var details = peg$posDetailsCache[pos];
    var p;

    if (details) {
      return details;
    } else {
      p = pos - 1;
      while (!peg$posDetailsCache[p]) {
        p--;
      }

      details = peg$posDetailsCache[p];
      details = {
        line: details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;

      return details;
    }
  }

  function peg$computeLocation(startPos, endPos) {
    var startPosDetails = peg$computePosDetails(startPos);
    var endPosDetails = peg$computePosDetails(endPos);

    return {
      source: peg$source,
      start: {
        offset: startPos,
        line: startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line: endPosDetails.line,
        column: endPosDetails.column
      }
    };
  }

  function peg$fail(expected) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected);
  }

  function peg$buildSimpleError(message, location) {
    return new peg$SyntaxError(message, null, null, location);
  }

  function peg$buildStructuredError(expected, found, location) {
    return new peg$SyntaxError(
      peg$SyntaxError.buildMessage(expected, found),
      expected,
      found,
      location
    );
  }

  function peg$parseSearch() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseWhitespace();
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$parseWhitespace();
    }
    s2 = peg$currPos;
    s3 = peg$parseExpression();
    if (s3 === peg$FAILED) {
      s3 = null;
    }
    s4 = [];
    s5 = peg$currPos;
    s6 = [];
    s7 = peg$parseWhitespace();
    while (s7 !== peg$FAILED) {
      s6.push(s7);
      s7 = peg$parseWhitespace();
    }
    s7 = peg$parseExpression();
    if (s7 !== peg$FAILED) {
      s5 = s7;
    } else {
      peg$currPos = s5;
      s5 = peg$FAILED;
    }
    while (s5 !== peg$FAILED) {
      s4.push(s5);
      s5 = peg$currPos;
      s6 = [];
      s7 = peg$parseWhitespace();
      while (s7 !== peg$FAILED) {
        s6.push(s7);
        s7 = peg$parseWhitespace();
      }
      s7 = peg$parseExpression();
      if (s7 !== peg$FAILED) {
        s5 = s7;
      } else {
        peg$currPos = s5;
        s5 = peg$FAILED;
      }
    }
    peg$savedPos = s2;
    s2 = peg$f0(s3, s4);
    s3 = [];
    s4 = peg$parseWhitespace();
    while (s4 !== peg$FAILED) {
      s3.push(s4);
      s4 = peg$parseWhitespace();
    }
    s0 = s2;

    return s0;
  }

  function peg$parseExpression() {
    var s0;

    s0 = peg$parseOrSeq();
    if (s0 === peg$FAILED) {
      s0 = peg$parseExpression2();
    }

    return s0;
  }

  function peg$parseExpression2() {
    var s0;

    s0 = peg$parseAndSeq();
    if (s0 === peg$FAILED) {
      s0 = peg$parseExpression3();
    }

    return s0;
  }

  function peg$parseExpression3() {
    var s0;

    s0 = peg$parseSubExpression();
    if (s0 === peg$FAILED) {
      s0 = peg$parseNotExpr();
      if (s0 === peg$FAILED) {
        s0 = peg$parseRegex();
        if (s0 === peg$FAILED) {
          s0 = peg$parseTerm();
        }
      }
    }

    return s0;
  }

  function peg$parseInternalExpr() {
    var s0;

    s0 = peg$parseIntAndSeq();
    if (s0 === peg$FAILED) {
      s0 = peg$parseIntOrSeq();
      if (s0 === peg$FAILED) {
        s0 = peg$parseSubExpression();
        if (s0 === peg$FAILED) {
          s0 = peg$parseIntNotExpr();
          if (s0 === peg$FAILED) {
            s0 = peg$parseRegex();
            if (s0 === peg$FAILED) {
              s0 = peg$parseIntTerm();
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseSubExpression() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    s2 = peg$parseEmptyParens();
    peg$silentFails--;
    if (s2 === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 40) {
        s2 = peg$c0;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e0); }
      }
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$parseWhitespace();
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$parseWhitespace();
        }
        s4 = peg$parseInternalExpr();
        if (s4 !== peg$FAILED) {
          s5 = [];
          s6 = peg$currPos;
          s7 = [];
          s8 = peg$parseWhitespace();
          if (s8 !== peg$FAILED) {
            while (s8 !== peg$FAILED) {
              s7.push(s8);
              s8 = peg$parseWhitespace();
            }
          } else {
            s7 = peg$FAILED;
          }
          if (s7 !== peg$FAILED) {
            s8 = peg$parseInternalExpr();
            if (s8 !== peg$FAILED) {
              s6 = s8;
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
          while (s6 !== peg$FAILED) {
            s5.push(s6);
            s6 = peg$currPos;
            s7 = [];
            s8 = peg$parseWhitespace();
            if (s8 !== peg$FAILED) {
              while (s8 !== peg$FAILED) {
                s7.push(s8);
                s8 = peg$parseWhitespace();
              }
            } else {
              s7 = peg$FAILED;
            }
            if (s7 !== peg$FAILED) {
              s8 = peg$parseInternalExpr();
              if (s8 !== peg$FAILED) {
                s6 = s8;
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
          }
          s6 = [];
          s7 = peg$parseWhitespace();
          while (s7 !== peg$FAILED) {
            s6.push(s7);
            s7 = peg$parseWhitespace();
          }
          if (input.charCodeAt(peg$currPos) === 41) {
            s7 = peg$c1;
            peg$currPos++;
          } else {
            s7 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e1); }
          }
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f1(s4, s5);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseAndSeq() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseExpression3();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseWhitespace();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseWhitespace();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        if (input.substr(peg$currPos, 3) === peg$c2) {
          s4 = peg$c2;
          peg$currPos += 3;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e2); }
        }
        if (s4 !== peg$FAILED) {
          peg$savedPos = s3;
          s4 = peg$f2(s1);
        }
        s3 = s4;
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$parseWhitespace();
          if (s5 !== peg$FAILED) {
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$parseWhitespace();
            }
          } else {
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseExpression2();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f3(s1, s3, s5);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseOrSeq() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseExpression2();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseWhitespace();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseWhitespace();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        if (input.substr(peg$currPos, 2) === peg$c3) {
          s4 = peg$c3;
          peg$currPos += 2;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e3); }
        }
        if (s4 !== peg$FAILED) {
          peg$savedPos = s3;
          s4 = peg$f4(s1);
        }
        s3 = s4;
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$parseWhitespace();
          if (s5 !== peg$FAILED) {
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$parseWhitespace();
            }
          } else {
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseExpression();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f5(s1, s3, s5);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseNotExpr() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c4) {
      s2 = peg$c4;
      peg$currPos += 3;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e4); }
    }
    if (s2 !== peg$FAILED) {
      peg$savedPos = s1;
      s2 = peg$f6();
    }
    s1 = s2;
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseWhitespace();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseWhitespace();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseSubExpression();
        if (s3 === peg$FAILED) {
          s3 = peg$parseNotExpr();
          if (s3 === peg$FAILED) {
            s3 = peg$parseRegex();
            if (s3 === peg$FAILED) {
              s3 = peg$parseTerm();
            }
          }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f7(s1, s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseIntAndSeq() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseSubExpression();
    if (s1 === peg$FAILED) {
      s1 = peg$parseIntNotExpr();
      if (s1 === peg$FAILED) {
        s1 = peg$parseRegex();
        if (s1 === peg$FAILED) {
          s1 = peg$parseIntTerm();
        }
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseWhitespace();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseWhitespace();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        if (input.substr(peg$currPos, 3) === peg$c2) {
          s4 = peg$c2;
          peg$currPos += 3;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e2); }
        }
        if (s4 !== peg$FAILED) {
          peg$savedPos = s3;
          s4 = peg$f8(s1);
        }
        s3 = s4;
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$parseWhitespace();
          if (s5 !== peg$FAILED) {
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$parseWhitespace();
            }
          } else {
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseSubExpression();
            if (s5 === peg$FAILED) {
              s5 = peg$parseIntNotExpr();
              if (s5 === peg$FAILED) {
                s5 = peg$parseRegex();
                if (s5 === peg$FAILED) {
                  s5 = peg$parseIntAndSeq();
                  if (s5 === peg$FAILED) {
                    s5 = peg$parseIntOrSeq();
                    if (s5 === peg$FAILED) {
                      s5 = peg$parseIntTerm();
                    }
                  }
                }
              }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f9(s1, s3, s5);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseIntOrSeq() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseSubExpression();
    if (s1 === peg$FAILED) {
      s1 = peg$parseIntNotExpr();
      if (s1 === peg$FAILED) {
        s1 = peg$parseRegex();
        if (s1 === peg$FAILED) {
          s1 = peg$parseIntTerm();
        }
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseWhitespace();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseWhitespace();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        if (input.substr(peg$currPos, 2) === peg$c3) {
          s4 = peg$c3;
          peg$currPos += 2;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e3); }
        }
        if (s4 !== peg$FAILED) {
          peg$savedPos = s3;
          s4 = peg$f10(s1);
        }
        s3 = s4;
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$parseWhitespace();
          if (s5 !== peg$FAILED) {
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$parseWhitespace();
            }
          } else {
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseSubExpression();
            if (s5 === peg$FAILED) {
              s5 = peg$parseIntNotExpr();
              if (s5 === peg$FAILED) {
                s5 = peg$parseRegex();
                if (s5 === peg$FAILED) {
                  s5 = peg$parseIntOrSeq();
                  if (s5 === peg$FAILED) {
                    s5 = peg$parseIntAndSeq();
                    if (s5 === peg$FAILED) {
                      s5 = peg$parseIntTerm();
                    }
                  }
                }
              }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f11(s1, s3, s5);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseIntNotExpr() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c4) {
      s2 = peg$c4;
      peg$currPos += 3;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e4); }
    }
    if (s2 !== peg$FAILED) {
      peg$savedPos = s1;
      s2 = peg$f12();
    }
    s1 = s2;
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseWhitespace();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseWhitespace();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInternalExpr();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f13(s1, s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTerm() {
    var s0;

    s0 = peg$parseNegaQualifier();
    if (s0 === peg$FAILED) {
      s0 = peg$parseQualifier();
      if (s0 === peg$FAILED) {
        s0 = peg$parseSExpression();
        if (s0 === peg$FAILED) {
          s0 = peg$parseQuotedString();
          if (s0 === peg$FAILED) {
            s0 = peg$parseBareWord();
          }
        }
      }
    }

    return s0;
  }

  function peg$parseIntTerm() {
    var s0;

    s0 = peg$parseNegaQualifier();
    if (s0 === peg$FAILED) {
      s0 = peg$parseQualifier();
      if (s0 === peg$FAILED) {
        s0 = peg$parseSExpression();
        if (s0 === peg$FAILED) {
          s0 = peg$parseQuotedString();
          if (s0 === peg$FAILED) {
            s0 = peg$parseIntBareWord();
          }
        }
      }
    }

    return s0;
  }

  function peg$parseNegaQualifier() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 45) {
      s1 = peg$c5;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e5); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseQualifier();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f14(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseQualifier() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c6) {
      s3 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s3 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e6); }
    }
    if (s3 === peg$FAILED) {
      if (input.substr(peg$currPos, 9).toLowerCase() === peg$c7) {
        s3 = input.substr(peg$currPos, 9);
        peg$currPos += 9;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e7); }
      }
    }
    if (s3 !== peg$FAILED) {
      peg$savedPos = s2;
      s3 = peg$f15(s3);
    }
    s2 = s3;
    if (s2 !== peg$FAILED) {
      s3 = peg$parseQuotedString();
      if (s3 === peg$FAILED) {
        s3 = peg$parseIntBareWord();
        if (s3 === peg$FAILED) {
          s3 = peg$parseEmpty();
        }
      }
      if (s3 !== peg$FAILED) {
        s1 = [ s2, s3 ];
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      s1 = peg$currPos;
      s2 = peg$currPos;
      if (input.substr(peg$currPos, 5).toLowerCase() === peg$c8) {
        s3 = input.substr(peg$currPos, 5);
        peg$currPos += 5;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e8); }
      }
      if (s3 === peg$FAILED) {
        if (input.substr(peg$currPos, 8).toLowerCase() === peg$c9) {
          s3 = input.substr(peg$currPos, 8);
          peg$currPos += 8;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e9); }
        }
      }
      if (s3 !== peg$FAILED) {
        peg$savedPos = s2;
        s3 = peg$f16(s3);
      }
      s2 = s3;
      if (s2 !== peg$FAILED) {
        s3 = peg$parseIntBareWord();
        if (s3 === peg$FAILED) {
          s3 = peg$parseEmpty();
        }
        if (s3 !== peg$FAILED) {
          s1 = [ s2, s3 ];
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
      if (s1 === peg$FAILED) {
        s1 = peg$currPos;
        s2 = peg$currPos;
        if (input.substr(peg$currPos, 5).toLowerCase() === peg$c10) {
          s3 = input.substr(peg$currPos, 5);
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e10); }
        }
        if (s3 === peg$FAILED) {
          if (input.substr(peg$currPos, 6).toLowerCase() === peg$c11) {
            s3 = input.substr(peg$currPos, 6);
            peg$currPos += 6;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e11); }
          }
          if (s3 === peg$FAILED) {
            if (input.substr(peg$currPos, 4).toLowerCase() === peg$c12) {
              s3 = input.substr(peg$currPos, 4);
              peg$currPos += 4;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e12); }
            }
          }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s2;
          s3 = peg$f17(s3);
        }
        s2 = s3;
        if (s2 !== peg$FAILED) {
          s3 = peg$parseIntBareWord();
          if (s3 === peg$FAILED) {
            s3 = peg$parseEmpty();
          }
          if (s3 !== peg$FAILED) {
            s1 = [ s2, s3 ];
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
        if (s1 === peg$FAILED) {
          s1 = peg$currPos;
          s2 = peg$currPos;
          if (input.substr(peg$currPos, 7).toLowerCase() === peg$c13) {
            s3 = input.substr(peg$currPos, 7);
            peg$currPos += 7;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e13); }
          }
          if (s3 === peg$FAILED) {
            if (input.substr(peg$currPos, 4).toLowerCase() === peg$c14) {
              s3 = input.substr(peg$currPos, 4);
              peg$currPos += 4;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e14); }
            }
          }
          if (s3 !== peg$FAILED) {
            peg$savedPos = s2;
            s3 = peg$f18(s3);
          }
          s2 = s3;
          if (s2 !== peg$FAILED) {
            s3 = peg$parseRegex();
            if (s3 === peg$FAILED) {
              s3 = peg$parseQuotedString();
              if (s3 === peg$FAILED) {
                s3 = peg$parseIntBareWord();
                if (s3 === peg$FAILED) {
                  s3 = peg$parseEmpty();
                }
              }
            }
            if (s3 !== peg$FAILED) {
              s1 = [ s2, s3 ];
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
          if (s1 === peg$FAILED) {
            s1 = peg$currPos;
            s2 = peg$currPos;
            if (input.substr(peg$currPos, 3).toLowerCase() === peg$c15) {
              s3 = input.substr(peg$currPos, 3);
              peg$currPos += 3;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e15); }
            }
            if (s3 !== peg$FAILED) {
              peg$savedPos = s2;
              s3 = peg$f19(s3);
            }
            s2 = s3;
            if (s2 !== peg$FAILED) {
              s3 = peg$parseQuotedString();
              if (s3 === peg$FAILED) {
                s3 = peg$parseIntBareWord();
                if (s3 === peg$FAILED) {
                  s3 = peg$parseEmpty();
                }
              }
              if (s3 !== peg$FAILED) {
                s1 = [ s2, s3 ];
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
            if (s1 === peg$FAILED) {
              s1 = peg$currPos;
              s2 = peg$currPos;
              if (input.substr(peg$currPos, 6).toLowerCase() === peg$c16) {
                s3 = input.substr(peg$currPos, 6);
                peg$currPos += 6;
              } else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$e16); }
              }
              if (s3 !== peg$FAILED) {
                peg$savedPos = s2;
                s3 = peg$f20(s3);
              }
              s2 = s3;
              if (s2 !== peg$FAILED) {
                s3 = peg$parseQuotedString();
                if (s3 === peg$FAILED) {
                  s3 = peg$parseIntBareWord();
                  if (s3 === peg$FAILED) {
                    s3 = peg$parseEmpty();
                  }
                }
                if (s3 !== peg$FAILED) {
                  s1 = [ s2, s3 ];
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
              if (s1 === peg$FAILED) {
                s1 = peg$currPos;
                s2 = peg$currPos;
                if (input.substr(peg$currPos, 5).toLowerCase() === peg$c17) {
                  s3 = input.substr(peg$currPos, 5);
                  peg$currPos += 5;
                } else {
                  s3 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$e17); }
                }
                if (s3 !== peg$FAILED) {
                  peg$savedPos = s2;
                  s3 = peg$f21(s3);
                }
                s2 = s3;
                if (s2 !== peg$FAILED) {
                  s3 = peg$parseRegex();
                  if (s3 === peg$FAILED) {
                    s3 = peg$parseQuotedString();
                    if (s3 === peg$FAILED) {
                      s3 = peg$parseIntBareWord();
                      if (s3 === peg$FAILED) {
                        s3 = peg$parseEmpty();
                      }
                    }
                  }
                  if (s3 !== peg$FAILED) {
                    s1 = [ s2, s3 ];
                  } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
                if (s1 === peg$FAILED) {
                  s1 = peg$currPos;
                  s2 = peg$currPos;
                  if (input.substr(peg$currPos, 8).toLowerCase() === peg$c18) {
                    s3 = input.substr(peg$currPos, 8);
                    peg$currPos += 8;
                  } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$e18); }
                  }
                  if (s3 !== peg$FAILED) {
                    peg$savedPos = s2;
                    s3 = peg$f22(s3);
                  }
                  s2 = s3;
                  if (s2 !== peg$FAILED) {
                    s3 = peg$parseRegex();
                    if (s3 === peg$FAILED) {
                      s3 = peg$parseQuotedString();
                      if (s3 === peg$FAILED) {
                        s3 = peg$parseIntBareWord();
                        if (s3 === peg$FAILED) {
                          s3 = peg$parseEmpty();
                        }
                      }
                    }
                    if (s3 !== peg$FAILED) {
                      s1 = [ s2, s3 ];
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                  }
                  if (s1 === peg$FAILED) {
                    s1 = peg$currPos;
                    s2 = peg$currPos;
                    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c19) {
                      s3 = input.substr(peg$currPos, 6);
                      peg$currPos += 6;
                    } else {
                      s3 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$e19); }
                    }
                    if (s3 !== peg$FAILED) {
                      peg$savedPos = s2;
                      s3 = peg$f23(s3);
                    }
                    s2 = s3;
                    if (s2 !== peg$FAILED) {
                      s3 = peg$parseQuotedString();
                      if (s3 === peg$FAILED) {
                        s3 = peg$parseIntBareWord();
                        if (s3 === peg$FAILED) {
                          s3 = peg$parseEmpty();
                        }
                      }
                      if (s3 !== peg$FAILED) {
                        s1 = [ s2, s3 ];
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                    if (s1 === peg$FAILED) {
                      s1 = peg$currPos;
                      s2 = peg$currPos;
                      if (input.substr(peg$currPos, 5).toLowerCase() === peg$c20) {
                        s3 = input.substr(peg$currPos, 5);
                        peg$currPos += 5;
                      } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$e20); }
                      }
                      if (s3 !== peg$FAILED) {
                        peg$savedPos = s2;
                        s3 = peg$f24(s3);
                      }
                      s2 = s3;
                      if (s2 !== peg$FAILED) {
                        s3 = peg$parseQuotedString();
                        if (s3 === peg$FAILED) {
                          s3 = peg$parseIntBareWord();
                          if (s3 === peg$FAILED) {
                            s3 = peg$parseEmpty();
                          }
                        }
                        if (s3 !== peg$FAILED) {
                          s1 = [ s2, s3 ];
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                      if (s1 === peg$FAILED) {
                        s1 = peg$currPos;
                        s2 = peg$currPos;
                        if (input.substr(peg$currPos, 3).toLowerCase() === peg$c21) {
                          s3 = input.substr(peg$currPos, 3);
                          peg$currPos += 3;
                        } else {
                          s3 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$e21); }
                        }
                        if (s3 !== peg$FAILED) {
                          peg$savedPos = s2;
                          s3 = peg$f25(s3);
                        }
                        s2 = s3;
                        if (s2 !== peg$FAILED) {
                          s3 = peg$parseQuotedString();
                          if (s3 === peg$FAILED) {
                            s3 = peg$parseIntBareWord();
                            if (s3 === peg$FAILED) {
                              s3 = peg$parseEmpty();
                            }
                          }
                          if (s3 !== peg$FAILED) {
                            s1 = [ s2, s3 ];
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                        if (s1 === peg$FAILED) {
                          s1 = peg$currPos;
                          s2 = peg$currPos;
                          if (input.substr(peg$currPos, 5).toLowerCase() === peg$c22) {
                            s3 = input.substr(peg$currPos, 5);
                            peg$currPos += 5;
                          } else {
                            s3 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$e22); }
                          }
                          if (s3 !== peg$FAILED) {
                            peg$savedPos = s2;
                            s3 = peg$f26(s3);
                          }
                          s2 = s3;
                          if (s2 !== peg$FAILED) {
                            s3 = peg$parseQuotedString();
                            if (s3 === peg$FAILED) {
                              s3 = peg$parseIntBareWord();
                              if (s3 === peg$FAILED) {
                                s3 = peg$parseEmpty();
                              }
                            }
                            if (s3 !== peg$FAILED) {
                              s1 = [ s2, s3 ];
                            } else {
                              peg$currPos = s1;
                              s1 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                          if (s1 === peg$FAILED) {
                            s1 = peg$currPos;
                            s2 = peg$currPos;
                            if (input.substr(peg$currPos, 7).toLowerCase() === peg$c23) {
                              s3 = input.substr(peg$currPos, 7);
                              peg$currPos += 7;
                            } else {
                              s3 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$e23); }
                            }
                            if (s3 !== peg$FAILED) {
                              peg$savedPos = s2;
                              s3 = peg$f27(s3);
                            }
                            s2 = s3;
                            if (s2 !== peg$FAILED) {
                              s3 = peg$parseQuotedString();
                              if (s3 === peg$FAILED) {
                                s3 = peg$parseIntBareWord();
                                if (s3 === peg$FAILED) {
                                  s3 = peg$parseEmpty();
                                }
                              }
                              if (s3 !== peg$FAILED) {
                                s1 = [ s2, s3 ];
                              } else {
                                peg$currPos = s1;
                                s1 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s1;
                              s1 = peg$FAILED;
                            }
                            if (s1 === peg$FAILED) {
                              s1 = peg$currPos;
                              s2 = peg$currPos;
                              if (input.substr(peg$currPos, 10).toLowerCase() === peg$c24) {
                                s3 = input.substr(peg$currPos, 10);
                                peg$currPos += 10;
                              } else {
                                s3 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$e24); }
                              }
                              if (s3 !== peg$FAILED) {
                                peg$savedPos = s2;
                                s3 = peg$f28(s3);
                              }
                              s2 = s3;
                              if (s2 !== peg$FAILED) {
                                s3 = peg$parseQuotedString();
                                if (s3 === peg$FAILED) {
                                  s3 = peg$parseIntBareWord();
                                  if (s3 === peg$FAILED) {
                                    s3 = peg$parseEmpty();
                                  }
                                }
                                if (s3 !== peg$FAILED) {
                                  s1 = [ s2, s3 ];
                                } else {
                                  peg$currPos = s1;
                                  s1 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s1;
                                s1 = peg$FAILED;
                              }
                              if (s1 === peg$FAILED) {
                                s1 = peg$currPos;
                                s2 = peg$currPos;
                                if (input.substr(peg$currPos, 9).toLowerCase() === peg$c25) {
                                  s3 = input.substr(peg$currPos, 9);
                                  peg$currPos += 9;
                                } else {
                                  s3 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$e25); }
                                }
                                if (s3 !== peg$FAILED) {
                                  peg$savedPos = s2;
                                  s3 = peg$f29(s3);
                                }
                                s2 = s3;
                                if (s2 !== peg$FAILED) {
                                  s3 = peg$parseQuotedString();
                                  if (s3 === peg$FAILED) {
                                    s3 = peg$parseIntBareWord();
                                    if (s3 === peg$FAILED) {
                                      s3 = peg$parseEmpty();
                                    }
                                  }
                                  if (s3 !== peg$FAILED) {
                                    s1 = [ s2, s3 ];
                                  } else {
                                    peg$currPos = s1;
                                    s1 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s1;
                                  s1 = peg$FAILED;
                                }
                                if (s1 === peg$FAILED) {
                                  s1 = peg$currPos;
                                  s2 = peg$currPos;
                                  if (input.substr(peg$currPos, 8).toLowerCase() === peg$c26) {
                                    s3 = input.substr(peg$currPos, 8);
                                    peg$currPos += 8;
                                  } else {
                                    s3 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$e26); }
                                  }
                                  if (s3 !== peg$FAILED) {
                                    peg$savedPos = s2;
                                    s3 = peg$f30(s3);
                                  }
                                  s2 = s3;
                                  if (s2 !== peg$FAILED) {
                                    s3 = peg$parseQuotedString();
                                    if (s3 === peg$FAILED) {
                                      s3 = peg$parseIntBareWord();
                                      if (s3 === peg$FAILED) {
                                        s3 = peg$parseEmpty();
                                      }
                                    }
                                    if (s3 !== peg$FAILED) {
                                      s1 = [ s2, s3 ];
                                    } else {
                                      peg$currPos = s1;
                                      s1 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s1;
                                    s1 = peg$FAILED;
                                  }
                                  if (s1 === peg$FAILED) {
                                    s1 = peg$currPos;
                                    s2 = peg$currPos;
                                    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c27) {
                                      s3 = input.substr(peg$currPos, 8);
                                      peg$currPos += 8;
                                    } else {
                                      s3 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$e27); }
                                    }
                                    if (s3 !== peg$FAILED) {
                                      peg$savedPos = s2;
                                      s3 = peg$f31(s3);
                                    }
                                    s2 = s3;
                                    if (s2 !== peg$FAILED) {
                                      s3 = peg$parseQuotedString();
                                      if (s3 === peg$FAILED) {
                                        s3 = peg$parseIntBareWord();
                                        if (s3 === peg$FAILED) {
                                          s3 = peg$parseEmpty();
                                        }
                                      }
                                      if (s3 !== peg$FAILED) {
                                        s1 = [ s2, s3 ];
                                      } else {
                                        peg$currPos = s1;
                                        s1 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s1;
                                      s1 = peg$FAILED;
                                    }
                                    if (s1 === peg$FAILED) {
                                      s1 = peg$currPos;
                                      s2 = peg$currPos;
                                      if (input.substr(peg$currPos, 9).toLowerCase() === peg$c28) {
                                        s3 = input.substr(peg$currPos, 9);
                                        peg$currPos += 9;
                                      } else {
                                        s3 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$e28); }
                                      }
                                      if (s3 !== peg$FAILED) {
                                        peg$savedPos = s2;
                                        s3 = peg$f32(s3);
                                      }
                                      s2 = s3;
                                      if (s2 !== peg$FAILED) {
                                        s3 = peg$parseQuotedString();
                                        if (s3 === peg$FAILED) {
                                          s3 = peg$parseIntBareWord();
                                          if (s3 === peg$FAILED) {
                                            s3 = peg$parseEmpty();
                                          }
                                        }
                                        if (s3 !== peg$FAILED) {
                                          s1 = [ s2, s3 ];
                                        } else {
                                          peg$currPos = s1;
                                          s1 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s1;
                                        s1 = peg$FAILED;
                                      }
                                      if (s1 === peg$FAILED) {
                                        s1 = peg$currPos;
                                        s2 = peg$currPos;
                                        if (input.substr(peg$currPos, 9).toLowerCase() === peg$c29) {
                                          s3 = input.substr(peg$currPos, 9);
                                          peg$currPos += 9;
                                        } else {
                                          s3 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$e29); }
                                        }
                                        if (s3 !== peg$FAILED) {
                                          peg$savedPos = s2;
                                          s3 = peg$f33(s3);
                                        }
                                        s2 = s3;
                                        if (s2 !== peg$FAILED) {
                                          s3 = peg$parseQuotedString();
                                          if (s3 === peg$FAILED) {
                                            s3 = peg$parseIntBareWord();
                                            if (s3 === peg$FAILED) {
                                              s3 = peg$parseEmpty();
                                            }
                                          }
                                          if (s3 !== peg$FAILED) {
                                            s1 = [ s2, s3 ];
                                          } else {
                                            peg$currPos = s1;
                                            s1 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s1;
                                          s1 = peg$FAILED;
                                        }
                                        if (s1 === peg$FAILED) {
                                          s1 = peg$currPos;
                                          s2 = peg$currPos;
                                          if (input.substr(peg$currPos, 12).toLowerCase() === peg$c30) {
                                            s3 = input.substr(peg$currPos, 12);
                                            peg$currPos += 12;
                                          } else {
                                            s3 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$e30); }
                                          }
                                          if (s3 !== peg$FAILED) {
                                            peg$savedPos = s2;
                                            s3 = peg$f34(s3);
                                          }
                                          s2 = s3;
                                          if (s2 !== peg$FAILED) {
                                            s3 = peg$parseQuotedString();
                                            if (s3 === peg$FAILED) {
                                              s3 = peg$parseIntBareWord();
                                              if (s3 === peg$FAILED) {
                                                s3 = peg$parseEmpty();
                                              }
                                            }
                                            if (s3 !== peg$FAILED) {
                                              s1 = [ s2, s3 ];
                                            } else {
                                              peg$currPos = s1;
                                              s1 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s1;
                                            s1 = peg$FAILED;
                                          }
                                          if (s1 === peg$FAILED) {
                                            s1 = peg$currPos;
                                            s2 = peg$currPos;
                                            if (input.substr(peg$currPos, 6).toLowerCase() === peg$c31) {
                                              s3 = input.substr(peg$currPos, 6);
                                              peg$currPos += 6;
                                            } else {
                                              s3 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$e31); }
                                            }
                                            if (s3 !== peg$FAILED) {
                                              peg$savedPos = s2;
                                              s3 = peg$f35(s3);
                                            }
                                            s2 = s3;
                                            if (s2 !== peg$FAILED) {
                                              s3 = peg$parseQuotedString();
                                              if (s3 === peg$FAILED) {
                                                s3 = peg$parseIntBareWord();
                                                if (s3 === peg$FAILED) {
                                                  s3 = peg$parseEmpty();
                                                }
                                              }
                                              if (s3 !== peg$FAILED) {
                                                s1 = [ s2, s3 ];
                                              } else {
                                                peg$currPos = s1;
                                                s1 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s1;
                                              s1 = peg$FAILED;
                                            }
                                            if (s1 === peg$FAILED) {
                                              s1 = peg$currPos;
                                              s2 = peg$currPos;
                                              if (input.substr(peg$currPos, 10).toLowerCase() === peg$c32) {
                                                s3 = input.substr(peg$currPos, 10);
                                                peg$currPos += 10;
                                              } else {
                                                s3 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$e32); }
                                              }
                                              if (s3 !== peg$FAILED) {
                                                peg$savedPos = s2;
                                                s3 = peg$f36(s3);
                                              }
                                              s2 = s3;
                                              if (s2 !== peg$FAILED) {
                                                s3 = peg$parseQuotedString();
                                                if (s3 === peg$FAILED) {
                                                  s3 = peg$parseIntBareWord();
                                                  if (s3 === peg$FAILED) {
                                                    s3 = peg$parseEmpty();
                                                  }
                                                }
                                                if (s3 !== peg$FAILED) {
                                                  s1 = [ s2, s3 ];
                                                } else {
                                                  peg$currPos = s1;
                                                  s1 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s1;
                                                s1 = peg$FAILED;
                                              }
                                              if (s1 === peg$FAILED) {
                                                s1 = peg$currPos;
                                                s2 = peg$currPos;
                                                if (input.substr(peg$currPos, 5).toLowerCase() === peg$c33) {
                                                  s3 = input.substr(peg$currPos, 5);
                                                  peg$currPos += 5;
                                                } else {
                                                  s3 = peg$FAILED;
                                                  if (peg$silentFails === 0) { peg$fail(peg$e33); }
                                                }
                                                if (s3 !== peg$FAILED) {
                                                  peg$savedPos = s2;
                                                  s3 = peg$f37(s3);
                                                }
                                                s2 = s3;
                                                if (s2 !== peg$FAILED) {
                                                  s3 = peg$parseQuotedString();
                                                  if (s3 === peg$FAILED) {
                                                    s3 = peg$parseIntBareWord();
                                                    if (s3 === peg$FAILED) {
                                                      s3 = peg$parseEmpty();
                                                    }
                                                  }
                                                  if (s3 !== peg$FAILED) {
                                                    s1 = [ s2, s3 ];
                                                  } else {
                                                    peg$currPos = s1;
                                                    s1 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s1;
                                                  s1 = peg$FAILED;
                                                }
                                                if (s1 === peg$FAILED) {
                                                  s1 = peg$currPos;
                                                  s2 = peg$currPos;
                                                  if (input.substr(peg$currPos, 6).toLowerCase() === peg$c34) {
                                                    s3 = input.substr(peg$currPos, 6);
                                                    peg$currPos += 6;
                                                  } else {
                                                    s3 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$e34); }
                                                  }
                                                  if (s3 !== peg$FAILED) {
                                                    peg$savedPos = s2;
                                                    s3 = peg$f38(s3);
                                                  }
                                                  s2 = s3;
                                                  if (s2 !== peg$FAILED) {
                                                    s3 = peg$parseQuotedString();
                                                    if (s3 === peg$FAILED) {
                                                      s3 = peg$parseIntBareWord();
                                                      if (s3 === peg$FAILED) {
                                                        s3 = peg$parseEmpty();
                                                      }
                                                    }
                                                    if (s3 !== peg$FAILED) {
                                                      s1 = [ s2, s3 ];
                                                    } else {
                                                      peg$currPos = s1;
                                                      s1 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s1;
                                                    s1 = peg$FAILED;
                                                  }
                                                  if (s1 === peg$FAILED) {
                                                    s1 = peg$currPos;
                                                    s2 = peg$currPos;
                                                    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c35) {
                                                      s3 = input.substr(peg$currPos, 8);
                                                      peg$currPos += 8;
                                                    } else {
                                                      s3 = peg$FAILED;
                                                      if (peg$silentFails === 0) { peg$fail(peg$e35); }
                                                    }
                                                    if (s3 !== peg$FAILED) {
                                                      peg$savedPos = s2;
                                                      s3 = peg$f39(s3);
                                                    }
                                                    s2 = s3;
                                                    if (s2 !== peg$FAILED) {
                                                      s3 = peg$parseQuotedString();
                                                      if (s3 === peg$FAILED) {
                                                        s3 = peg$parseIntBareWord();
                                                        if (s3 === peg$FAILED) {
                                                          s3 = peg$parseEmpty();
                                                        }
                                                      }
                                                      if (s3 !== peg$FAILED) {
                                                        s1 = [ s2, s3 ];
                                                      } else {
                                                        peg$currPos = s1;
                                                        s1 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s1;
                                                      s1 = peg$FAILED;
                                                    }
                                                    if (s1 === peg$FAILED) {
                                                      s1 = peg$currPos;
                                                      s2 = peg$currPos;
                                                      if (input.substr(peg$currPos, 3).toLowerCase() === peg$c36) {
                                                        s3 = input.substr(peg$currPos, 3);
                                                        peg$currPos += 3;
                                                      } else {
                                                        s3 = peg$FAILED;
                                                        if (peg$silentFails === 0) { peg$fail(peg$e36); }
                                                      }
                                                      if (s3 !== peg$FAILED) {
                                                        peg$savedPos = s2;
                                                        s3 = peg$f40(s3);
                                                      }
                                                      s2 = s3;
                                                      if (s2 !== peg$FAILED) {
                                                        s3 = peg$parseQuotedString();
                                                        if (s3 === peg$FAILED) {
                                                          s3 = peg$parseIntBareWord();
                                                          if (s3 === peg$FAILED) {
                                                            s3 = peg$parseEmpty();
                                                          }
                                                        }
                                                        if (s3 !== peg$FAILED) {
                                                          s1 = [ s2, s3 ];
                                                        } else {
                                                          peg$currPos = s1;
                                                          s1 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s1;
                                                        s1 = peg$FAILED;
                                                      }
                                                      if (s1 === peg$FAILED) {
                                                        s1 = peg$currPos;
                                                        s2 = peg$currPos;
                                                        if (input.substr(peg$currPos, 9).toLowerCase() === peg$c37) {
                                                          s3 = input.substr(peg$currPos, 9);
                                                          peg$currPos += 9;
                                                        } else {
                                                          s3 = peg$FAILED;
                                                          if (peg$silentFails === 0) { peg$fail(peg$e37); }
                                                        }
                                                        if (s3 !== peg$FAILED) {
                                                          peg$savedPos = s2;
                                                          s3 = peg$f41(s3);
                                                        }
                                                        s2 = s3;
                                                        if (s2 !== peg$FAILED) {
                                                          s3 = peg$parseQuotedString();
                                                          if (s3 === peg$FAILED) {
                                                            s3 = peg$parseIntBareWord();
                                                            if (s3 === peg$FAILED) {
                                                              s3 = peg$parseEmpty();
                                                            }
                                                          }
                                                          if (s3 !== peg$FAILED) {
                                                            s1 = [ s2, s3 ];
                                                          } else {
                                                            peg$currPos = s1;
                                                            s1 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s1;
                                                          s1 = peg$FAILED;
                                                        }
                                                        if (s1 === peg$FAILED) {
                                                          s1 = peg$currPos;
                                                          s2 = peg$currPos;
                                                          if (input.substr(peg$currPos, 9).toLowerCase() === peg$c38) {
                                                            s3 = input.substr(peg$currPos, 9);
                                                            peg$currPos += 9;
                                                          } else {
                                                            s3 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$e38); }
                                                          }
                                                          if (s3 !== peg$FAILED) {
                                                            peg$savedPos = s2;
                                                            s3 = peg$f42(s3);
                                                          }
                                                          s2 = s3;
                                                          if (s2 !== peg$FAILED) {
                                                            s3 = peg$parseQuotedString();
                                                            if (s3 === peg$FAILED) {
                                                              s3 = peg$parseIntBareWord();
                                                              if (s3 === peg$FAILED) {
                                                                s3 = peg$parseEmpty();
                                                              }
                                                            }
                                                            if (s3 !== peg$FAILED) {
                                                              s1 = [ s2, s3 ];
                                                            } else {
                                                              peg$currPos = s1;
                                                              s1 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s1;
                                                            s1 = peg$FAILED;
                                                          }
                                                          if (s1 === peg$FAILED) {
                                                            s1 = peg$currPos;
                                                            s2 = peg$currPos;
                                                            if (input.substr(peg$currPos, 7).toLowerCase() === peg$c39) {
                                                              s3 = input.substr(peg$currPos, 7);
                                                              peg$currPos += 7;
                                                            } else {
                                                              s3 = peg$FAILED;
                                                              if (peg$silentFails === 0) { peg$fail(peg$e39); }
                                                            }
                                                            if (s3 !== peg$FAILED) {
                                                              peg$savedPos = s2;
                                                              s3 = peg$f43(s3);
                                                            }
                                                            s2 = s3;
                                                            if (s2 !== peg$FAILED) {
                                                              s3 = peg$parseQuotedString();
                                                              if (s3 === peg$FAILED) {
                                                                s3 = peg$parseIntBareWord();
                                                                if (s3 === peg$FAILED) {
                                                                  s3 = peg$parseEmpty();
                                                                }
                                                              }
                                                              if (s3 !== peg$FAILED) {
                                                                s1 = [ s2, s3 ];
                                                              } else {
                                                                peg$currPos = s1;
                                                                s1 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s1;
                                                              s1 = peg$FAILED;
                                                            }
                                                            if (s1 === peg$FAILED) {
                                                              s1 = peg$currPos;
                                                              s2 = peg$currPos;
                                                              if (input.substr(peg$currPos, 7).toLowerCase() === peg$c40) {
                                                                s3 = input.substr(peg$currPos, 7);
                                                                peg$currPos += 7;
                                                              } else {
                                                                s3 = peg$FAILED;
                                                                if (peg$silentFails === 0) { peg$fail(peg$e40); }
                                                              }
                                                              if (s3 !== peg$FAILED) {
                                                                peg$savedPos = s2;
                                                                s3 = peg$f44(s3);
                                                              }
                                                              s2 = s3;
                                                              if (s2 !== peg$FAILED) {
                                                                s3 = peg$parseQuotedString();
                                                                if (s3 === peg$FAILED) {
                                                                  s3 = peg$parseIntBareWord();
                                                                  if (s3 === peg$FAILED) {
                                                                    s3 = peg$parseEmpty();
                                                                  }
                                                                }
                                                                if (s3 !== peg$FAILED) {
                                                                  s1 = [ s2, s3 ];
                                                                } else {
                                                                  peg$currPos = s1;
                                                                  s1 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s1;
                                                                s1 = peg$FAILED;
                                                              }
                                                              if (s1 === peg$FAILED) {
                                                                s1 = peg$currPos;
                                                                s2 = peg$currPos;
                                                                if (input.substr(peg$currPos, 7).toLowerCase() === peg$c41) {
                                                                  s3 = input.substr(peg$currPos, 7);
                                                                  peg$currPos += 7;
                                                                } else {
                                                                  s3 = peg$FAILED;
                                                                  if (peg$silentFails === 0) { peg$fail(peg$e41); }
                                                                }
                                                                if (s3 !== peg$FAILED) {
                                                                  peg$savedPos = s2;
                                                                  s3 = peg$f45(s3);
                                                                }
                                                                s2 = s3;
                                                                if (s2 !== peg$FAILED) {
                                                                  s3 = peg$parseQuotedString();
                                                                  if (s3 === peg$FAILED) {
                                                                    s3 = peg$parseIntBareWord();
                                                                    if (s3 === peg$FAILED) {
                                                                      s3 = peg$parseEmpty();
                                                                    }
                                                                  }
                                                                  if (s3 !== peg$FAILED) {
                                                                    s1 = [ s2, s3 ];
                                                                  } else {
                                                                    peg$currPos = s1;
                                                                    s1 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s1;
                                                                  s1 = peg$FAILED;
                                                                }
                                                                if (s1 === peg$FAILED) {
                                                                  s1 = peg$currPos;
                                                                  s2 = peg$currPos;
                                                                  if (input.substr(peg$currPos, 5).toLowerCase() === peg$c42) {
                                                                    s3 = input.substr(peg$currPos, 5);
                                                                    peg$currPos += 5;
                                                                  } else {
                                                                    s3 = peg$FAILED;
                                                                    if (peg$silentFails === 0) { peg$fail(peg$e42); }
                                                                  }
                                                                  if (s3 !== peg$FAILED) {
                                                                    peg$savedPos = s2;
                                                                    s3 = peg$f46(s3);
                                                                  }
                                                                  s2 = s3;
                                                                  if (s2 !== peg$FAILED) {
                                                                    s3 = peg$parseQuotedString();
                                                                    if (s3 === peg$FAILED) {
                                                                      s3 = peg$parseIntBareWord();
                                                                      if (s3 === peg$FAILED) {
                                                                        s3 = peg$parseEmpty();
                                                                      }
                                                                    }
                                                                    if (s3 !== peg$FAILED) {
                                                                      s1 = [ s2, s3 ];
                                                                    } else {
                                                                      peg$currPos = s1;
                                                                      s1 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s1;
                                                                    s1 = peg$FAILED;
                                                                  }
                                                                  if (s1 === peg$FAILED) {
                                                                    s1 = peg$currPos;
                                                                    s2 = peg$currPos;
                                                                    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c43) {
                                                                      s3 = input.substr(peg$currPos, 5);
                                                                      peg$currPos += 5;
                                                                    } else {
                                                                      s3 = peg$FAILED;
                                                                      if (peg$silentFails === 0) { peg$fail(peg$e43); }
                                                                    }
                                                                    if (s3 !== peg$FAILED) {
                                                                      peg$savedPos = s2;
                                                                      s3 = peg$f47(s3);
                                                                    }
                                                                    s2 = s3;
                                                                    if (s2 !== peg$FAILED) {
                                                                      s3 = peg$parseQuotedString();
                                                                      if (s3 === peg$FAILED) {
                                                                        s3 = peg$parseIntBareWord();
                                                                        if (s3 === peg$FAILED) {
                                                                          s3 = peg$parseEmpty();
                                                                        }
                                                                      }
                                                                      if (s3 !== peg$FAILED) {
                                                                        s1 = [ s2, s3 ];
                                                                      } else {
                                                                        peg$currPos = s1;
                                                                        s1 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s1;
                                                                      s1 = peg$FAILED;
                                                                    }
                                                                    if (s1 === peg$FAILED) {
                                                                      s1 = peg$currPos;
                                                                      s2 = peg$currPos;
                                                                      if (input.substr(peg$currPos, 10).toLowerCase() === peg$c44) {
                                                                        s3 = input.substr(peg$currPos, 10);
                                                                        peg$currPos += 10;
                                                                      } else {
                                                                        s3 = peg$FAILED;
                                                                        if (peg$silentFails === 0) { peg$fail(peg$e44); }
                                                                      }
                                                                      if (s3 !== peg$FAILED) {
                                                                        peg$savedPos = s2;
                                                                        s3 = peg$f48(s3);
                                                                      }
                                                                      s2 = s3;
                                                                      if (s2 !== peg$FAILED) {
                                                                        s3 = peg$parseQuotedString();
                                                                        if (s3 === peg$FAILED) {
                                                                          s3 = peg$parseIntBareWord();
                                                                          if (s3 === peg$FAILED) {
                                                                            s3 = peg$parseEmpty();
                                                                          }
                                                                        }
                                                                        if (s3 !== peg$FAILED) {
                                                                          s1 = [ s2, s3 ];
                                                                        } else {
                                                                          peg$currPos = s1;
                                                                          s1 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s1;
                                                                        s1 = peg$FAILED;
                                                                      }
                                                                      if (s1 === peg$FAILED) {
                                                                        s1 = peg$currPos;
                                                                        s2 = peg$currPos;
                                                                        if (input.substr(peg$currPos, 13).toLowerCase() === peg$c45) {
                                                                          s3 = input.substr(peg$currPos, 13);
                                                                          peg$currPos += 13;
                                                                        } else {
                                                                          s3 = peg$FAILED;
                                                                          if (peg$silentFails === 0) { peg$fail(peg$e45); }
                                                                        }
                                                                        if (s3 !== peg$FAILED) {
                                                                          peg$savedPos = s2;
                                                                          s3 = peg$f49(s3);
                                                                        }
                                                                        s2 = s3;
                                                                        if (s2 !== peg$FAILED) {
                                                                          s3 = peg$parseQuotedString();
                                                                          if (s3 === peg$FAILED) {
                                                                            s3 = peg$parseIntBareWord();
                                                                            if (s3 === peg$FAILED) {
                                                                              s3 = peg$parseEmpty();
                                                                            }
                                                                          }
                                                                          if (s3 !== peg$FAILED) {
                                                                            s1 = [ s2, s3 ];
                                                                          } else {
                                                                            peg$currPos = s1;
                                                                            s1 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s1;
                                                                          s1 = peg$FAILED;
                                                                        }
                                                                        if (s1 === peg$FAILED) {
                                                                          s1 = peg$currPos;
                                                                          s2 = peg$currPos;
                                                                          if (input.substr(peg$currPos, 10).toLowerCase() === peg$c46) {
                                                                            s3 = input.substr(peg$currPos, 10);
                                                                            peg$currPos += 10;
                                                                          } else {
                                                                            s3 = peg$FAILED;
                                                                            if (peg$silentFails === 0) { peg$fail(peg$e46); }
                                                                          }
                                                                          if (s3 !== peg$FAILED) {
                                                                            peg$savedPos = s2;
                                                                            s3 = peg$f50(s3);
                                                                          }
                                                                          s2 = s3;
                                                                          if (s2 !== peg$FAILED) {
                                                                            s3 = peg$parseQuotedString();
                                                                            if (s3 === peg$FAILED) {
                                                                              s3 = peg$parseIntBareWord();
                                                                              if (s3 === peg$FAILED) {
                                                                                s3 = peg$parseEmpty();
                                                                              }
                                                                            }
                                                                            if (s3 !== peg$FAILED) {
                                                                              s1 = [ s2, s3 ];
                                                                            } else {
                                                                              peg$currPos = s1;
                                                                              s1 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s1;
                                                                            s1 = peg$FAILED;
                                                                          }
                                                                          if (s1 === peg$FAILED) {
                                                                            s1 = peg$currPos;
                                                                            s2 = peg$currPos;
                                                                            if (input.substr(peg$currPos, 7).toLowerCase() === peg$c47) {
                                                                              s3 = input.substr(peg$currPos, 7);
                                                                              peg$currPos += 7;
                                                                            } else {
                                                                              s3 = peg$FAILED;
                                                                              if (peg$silentFails === 0) { peg$fail(peg$e47); }
                                                                            }
                                                                            if (s3 !== peg$FAILED) {
                                                                              peg$savedPos = s2;
                                                                              s3 = peg$f51(s3);
                                                                            }
                                                                            s2 = s3;
                                                                            if (s2 !== peg$FAILED) {
                                                                              s3 = peg$parseQuotedString();
                                                                              if (s3 === peg$FAILED) {
                                                                                s3 = peg$parseIntBareWord();
                                                                                if (s3 === peg$FAILED) {
                                                                                  s3 = peg$parseEmpty();
                                                                                }
                                                                              }
                                                                              if (s3 !== peg$FAILED) {
                                                                                s1 = [ s2, s3 ];
                                                                              } else {
                                                                                peg$currPos = s1;
                                                                                s1 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s1;
                                                                              s1 = peg$FAILED;
                                                                            }
                                                                            if (s1 === peg$FAILED) {
                                                                              s1 = peg$currPos;
                                                                              s2 = peg$currPos;
                                                                              if (input.substr(peg$currPos, 9).toLowerCase() === peg$c48) {
                                                                                s3 = input.substr(peg$currPos, 9);
                                                                                peg$currPos += 9;
                                                                              } else {
                                                                                s3 = peg$FAILED;
                                                                                if (peg$silentFails === 0) { peg$fail(peg$e48); }
                                                                              }
                                                                              if (s3 !== peg$FAILED) {
                                                                                peg$savedPos = s2;
                                                                                s3 = peg$f52(s3);
                                                                              }
                                                                              s2 = s3;
                                                                              if (s2 !== peg$FAILED) {
                                                                                s3 = peg$parseQuotedString();
                                                                                if (s3 === peg$FAILED) {
                                                                                  s3 = peg$parseIntBareWord();
                                                                                  if (s3 === peg$FAILED) {
                                                                                    s3 = peg$parseEmpty();
                                                                                  }
                                                                                }
                                                                                if (s3 !== peg$FAILED) {
                                                                                  s1 = [ s2, s3 ];
                                                                                } else {
                                                                                  peg$currPos = s1;
                                                                                  s1 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s1;
                                                                                s1 = peg$FAILED;
                                                                              }
                                                                              if (s1 === peg$FAILED) {
                                                                                s1 = peg$currPos;
                                                                                s2 = peg$currPos;
                                                                                if (input.substr(peg$currPos, 6).toLowerCase() === peg$c49) {
                                                                                  s3 = input.substr(peg$currPos, 6);
                                                                                  peg$currPos += 6;
                                                                                } else {
                                                                                  s3 = peg$FAILED;
                                                                                  if (peg$silentFails === 0) { peg$fail(peg$e49); }
                                                                                }
                                                                                if (s3 !== peg$FAILED) {
                                                                                  peg$savedPos = s2;
                                                                                  s3 = peg$f53(s3);
                                                                                }
                                                                                s2 = s3;
                                                                                if (s2 !== peg$FAILED) {
                                                                                  s3 = peg$parseQuotedString();
                                                                                  if (s3 === peg$FAILED) {
                                                                                    s3 = peg$parseIntBareWord();
                                                                                    if (s3 === peg$FAILED) {
                                                                                      s3 = peg$parseEmpty();
                                                                                    }
                                                                                  }
                                                                                  if (s3 !== peg$FAILED) {
                                                                                    s1 = [ s2, s3 ];
                                                                                  } else {
                                                                                    peg$currPos = s1;
                                                                                    s1 = peg$FAILED;
                                                                                  }
                                                                                } else {
                                                                                  peg$currPos = s1;
                                                                                  s1 = peg$FAILED;
                                                                                }
                                                                                if (s1 === peg$FAILED) {
                                                                                  s1 = peg$currPos;
                                                                                  s2 = peg$currPos;
                                                                                  if (input.substr(peg$currPos, 7).toLowerCase() === peg$c50) {
                                                                                    s3 = input.substr(peg$currPos, 7);
                                                                                    peg$currPos += 7;
                                                                                  } else {
                                                                                    s3 = peg$FAILED;
                                                                                    if (peg$silentFails === 0) { peg$fail(peg$e50); }
                                                                                  }
                                                                                  if (s3 !== peg$FAILED) {
                                                                                    peg$savedPos = s2;
                                                                                    s3 = peg$f54(s3);
                                                                                  }
                                                                                  s2 = s3;
                                                                                  if (s2 !== peg$FAILED) {
                                                                                    s3 = peg$parseQuotedString();
                                                                                    if (s3 === peg$FAILED) {
                                                                                      s3 = peg$parseIntBareWord();
                                                                                      if (s3 === peg$FAILED) {
                                                                                        s3 = peg$parseEmpty();
                                                                                      }
                                                                                    }
                                                                                    if (s3 !== peg$FAILED) {
                                                                                      s1 = [ s2, s3 ];
                                                                                    } else {
                                                                                      peg$currPos = s1;
                                                                                      s1 = peg$FAILED;
                                                                                    }
                                                                                  } else {
                                                                                    peg$currPos = s1;
                                                                                    s1 = peg$FAILED;
                                                                                  }
                                                                                  if (s1 === peg$FAILED) {
                                                                                    s1 = peg$currPos;
                                                                                    s2 = peg$currPos;
                                                                                    if (input.substr(peg$currPos, 9).toLowerCase() === peg$c51) {
                                                                                      s3 = input.substr(peg$currPos, 9);
                                                                                      peg$currPos += 9;
                                                                                    } else {
                                                                                      s3 = peg$FAILED;
                                                                                      if (peg$silentFails === 0) { peg$fail(peg$e51); }
                                                                                    }
                                                                                    if (s3 !== peg$FAILED) {
                                                                                      peg$savedPos = s2;
                                                                                      s3 = peg$f55(s3);
                                                                                    }
                                                                                    s2 = s3;
                                                                                    if (s2 !== peg$FAILED) {
                                                                                      s3 = peg$parseQuotedString();
                                                                                      if (s3 === peg$FAILED) {
                                                                                        s3 = peg$parseIntBareWord();
                                                                                        if (s3 === peg$FAILED) {
                                                                                          s3 = peg$parseEmpty();
                                                                                        }
                                                                                      }
                                                                                      if (s3 !== peg$FAILED) {
                                                                                        s1 = [ s2, s3 ];
                                                                                      } else {
                                                                                        peg$currPos = s1;
                                                                                        s1 = peg$FAILED;
                                                                                      }
                                                                                    } else {
                                                                                      peg$currPos = s1;
                                                                                      s1 = peg$FAILED;
                                                                                    }
                                                                                    if (s1 === peg$FAILED) {
                                                                                      s1 = peg$currPos;
                                                                                      s2 = peg$currPos;
                                                                                      if (input.substr(peg$currPos, 7).toLowerCase() === peg$c52) {
                                                                                        s3 = input.substr(peg$currPos, 7);
                                                                                        peg$currPos += 7;
                                                                                      } else {
                                                                                        s3 = peg$FAILED;
                                                                                        if (peg$silentFails === 0) { peg$fail(peg$e52); }
                                                                                      }
                                                                                      if (s3 !== peg$FAILED) {
                                                                                        peg$savedPos = s2;
                                                                                        s3 = peg$f56(s3);
                                                                                      }
                                                                                      s2 = s3;
                                                                                      if (s2 !== peg$FAILED) {
                                                                                        s3 = peg$parseQuotedString();
                                                                                        if (s3 === peg$FAILED) {
                                                                                          s3 = peg$parseIntBareWord();
                                                                                          if (s3 === peg$FAILED) {
                                                                                            s3 = peg$parseEmpty();
                                                                                          }
                                                                                        }
                                                                                        if (s3 !== peg$FAILED) {
                                                                                          s1 = [ s2, s3 ];
                                                                                        } else {
                                                                                          peg$currPos = s1;
                                                                                          s1 = peg$FAILED;
                                                                                        }
                                                                                      } else {
                                                                                        peg$currPos = s1;
                                                                                        s1 = peg$FAILED;
                                                                                      }
                                                                                      if (s1 === peg$FAILED) {
                                                                                        s1 = peg$currPos;
                                                                                        s2 = peg$currPos;
                                                                                        if (input.substr(peg$currPos, 7).toLowerCase() === peg$c53) {
                                                                                          s3 = input.substr(peg$currPos, 7);
                                                                                          peg$currPos += 7;
                                                                                        } else {
                                                                                          s3 = peg$FAILED;
                                                                                          if (peg$silentFails === 0) { peg$fail(peg$e53); }
                                                                                        }
                                                                                        if (s3 !== peg$FAILED) {
                                                                                          peg$savedPos = s2;
                                                                                          s3 = peg$f57(s3);
                                                                                        }
                                                                                        s2 = s3;
                                                                                        if (s2 !== peg$FAILED) {
                                                                                          s3 = peg$parseQuotedString();
                                                                                          if (s3 === peg$FAILED) {
                                                                                            s3 = peg$parseIntBareWord();
                                                                                            if (s3 === peg$FAILED) {
                                                                                              s3 = peg$parseEmpty();
                                                                                            }
                                                                                          }
                                                                                          if (s3 !== peg$FAILED) {
                                                                                            s1 = [ s2, s3 ];
                                                                                          } else {
                                                                                            peg$currPos = s1;
                                                                                            s1 = peg$FAILED;
                                                                                          }
                                                                                        } else {
                                                                                          peg$currPos = s1;
                                                                                          s1 = peg$FAILED;
                                                                                        }
                                                                                        if (s1 === peg$FAILED) {
                                                                                          s1 = peg$currPos;
                                                                                          s2 = peg$currPos;
                                                                                          if (input.substr(peg$currPos, 8).toLowerCase() === peg$c54) {
                                                                                            s3 = input.substr(peg$currPos, 8);
                                                                                            peg$currPos += 8;
                                                                                          } else {
                                                                                            s3 = peg$FAILED;
                                                                                            if (peg$silentFails === 0) { peg$fail(peg$e54); }
                                                                                          }
                                                                                          if (s3 !== peg$FAILED) {
                                                                                            peg$savedPos = s2;
                                                                                            s3 = peg$f58(s3);
                                                                                          }
                                                                                          s2 = s3;
                                                                                          if (s2 !== peg$FAILED) {
                                                                                            s3 = peg$parseQuotedString();
                                                                                            if (s3 === peg$FAILED) {
                                                                                              s3 = peg$parseIntBareWord();
                                                                                              if (s3 === peg$FAILED) {
                                                                                                s3 = peg$parseEmpty();
                                                                                              }
                                                                                            }
                                                                                            if (s3 !== peg$FAILED) {
                                                                                              s1 = [ s2, s3 ];
                                                                                            } else {
                                                                                              peg$currPos = s1;
                                                                                              s1 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s1;
                                                                                            s1 = peg$FAILED;
                                                                                          }
                                                                                          if (s1 === peg$FAILED) {
                                                                                            s1 = peg$currPos;
                                                                                            s2 = peg$currPos;
                                                                                            if (input.substr(peg$currPos, 4).toLowerCase() === peg$c55) {
                                                                                              s3 = input.substr(peg$currPos, 4);
                                                                                              peg$currPos += 4;
                                                                                            } else {
                                                                                              s3 = peg$FAILED;
                                                                                              if (peg$silentFails === 0) { peg$fail(peg$e55); }
                                                                                            }
                                                                                            if (s3 !== peg$FAILED) {
                                                                                              peg$savedPos = s2;
                                                                                              s3 = peg$f59(s3);
                                                                                            }
                                                                                            s2 = s3;
                                                                                            if (s2 !== peg$FAILED) {
                                                                                              s3 = peg$parseQuotedString();
                                                                                              if (s3 === peg$FAILED) {
                                                                                                s3 = peg$parseIntBareWord();
                                                                                                if (s3 === peg$FAILED) {
                                                                                                  s3 = peg$parseEmpty();
                                                                                                }
                                                                                              }
                                                                                              if (s3 !== peg$FAILED) {
                                                                                                s1 = [ s2, s3 ];
                                                                                              } else {
                                                                                                peg$currPos = s1;
                                                                                                s1 = peg$FAILED;
                                                                                              }
                                                                                            } else {
                                                                                              peg$currPos = s1;
                                                                                              s1 = peg$FAILED;
                                                                                            }
                                                                                            if (s1 === peg$FAILED) {
                                                                                              s1 = peg$currPos;
                                                                                              s2 = peg$currPos;
                                                                                              if (input.substr(peg$currPos, 9).toLowerCase() === peg$c56) {
                                                                                                s3 = input.substr(peg$currPos, 9);
                                                                                                peg$currPos += 9;
                                                                                              } else {
                                                                                                s3 = peg$FAILED;
                                                                                                if (peg$silentFails === 0) { peg$fail(peg$e56); }
                                                                                              }
                                                                                              if (s3 !== peg$FAILED) {
                                                                                                peg$savedPos = s2;
                                                                                                s3 = peg$f60(s3);
                                                                                              }
                                                                                              s2 = s3;
                                                                                              if (s2 !== peg$FAILED) {
                                                                                                s3 = peg$parseQuotedString();
                                                                                                if (s3 === peg$FAILED) {
                                                                                                  s3 = peg$parseIntBareWord();
                                                                                                  if (s3 === peg$FAILED) {
                                                                                                    s3 = peg$parseEmpty();
                                                                                                  }
                                                                                                }
                                                                                                if (s3 !== peg$FAILED) {
                                                                                                  s1 = [ s2, s3 ];
                                                                                                } else {
                                                                                                  peg$currPos = s1;
                                                                                                  s1 = peg$FAILED;
                                                                                                }
                                                                                              } else {
                                                                                                peg$currPos = s1;
                                                                                                s1 = peg$FAILED;
                                                                                              }
                                                                                              if (s1 === peg$FAILED) {
                                                                                                s1 = peg$currPos;
                                                                                                s2 = peg$currPos;
                                                                                                if (input.substr(peg$currPos, 9).toLowerCase() === peg$c57) {
                                                                                                  s3 = input.substr(peg$currPos, 9);
                                                                                                  peg$currPos += 9;
                                                                                                } else {
                                                                                                  s3 = peg$FAILED;
                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$e57); }
                                                                                                }
                                                                                                if (s3 !== peg$FAILED) {
                                                                                                  peg$savedPos = s2;
                                                                                                  s3 = peg$f61(s3);
                                                                                                }
                                                                                                s2 = s3;
                                                                                                if (s2 !== peg$FAILED) {
                                                                                                  s3 = peg$parseQuotedString();
                                                                                                  if (s3 === peg$FAILED) {
                                                                                                    s3 = peg$parseIntBareWord();
                                                                                                    if (s3 === peg$FAILED) {
                                                                                                      s3 = peg$parseEmpty();
                                                                                                    }
                                                                                                  }
                                                                                                  if (s3 !== peg$FAILED) {
                                                                                                    s1 = [ s2, s3 ];
                                                                                                  } else {
                                                                                                    peg$currPos = s1;
                                                                                                    s1 = peg$FAILED;
                                                                                                  }
                                                                                                } else {
                                                                                                  peg$currPos = s1;
                                                                                                  s1 = peg$FAILED;
                                                                                                }
                                                                                                if (s1 === peg$FAILED) {
                                                                                                  s1 = peg$currPos;
                                                                                                  s2 = peg$currPos;
                                                                                                  if (input.substr(peg$currPos, 13).toLowerCase() === peg$c58) {
                                                                                                    s3 = input.substr(peg$currPos, 13);
                                                                                                    peg$currPos += 13;
                                                                                                  } else {
                                                                                                    s3 = peg$FAILED;
                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$e58); }
                                                                                                  }
                                                                                                  if (s3 !== peg$FAILED) {
                                                                                                    peg$savedPos = s2;
                                                                                                    s3 = peg$f62(s3);
                                                                                                  }
                                                                                                  s2 = s3;
                                                                                                  if (s2 !== peg$FAILED) {
                                                                                                    s3 = peg$parseQuotedString();
                                                                                                    if (s3 === peg$FAILED) {
                                                                                                      s3 = peg$parseIntBareWord();
                                                                                                      if (s3 === peg$FAILED) {
                                                                                                        s3 = peg$parseEmpty();
                                                                                                      }
                                                                                                    }
                                                                                                    if (s3 !== peg$FAILED) {
                                                                                                      s1 = [ s2, s3 ];
                                                                                                    } else {
                                                                                                      peg$currPos = s1;
                                                                                                      s1 = peg$FAILED;
                                                                                                    }
                                                                                                  } else {
                                                                                                    peg$currPos = s1;
                                                                                                    s1 = peg$FAILED;
                                                                                                  }
                                                                                                  if (s1 === peg$FAILED) {
                                                                                                    s1 = peg$currPos;
                                                                                                    s2 = peg$currPos;
                                                                                                    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c59) {
                                                                                                      s3 = input.substr(peg$currPos, 6);
                                                                                                      peg$currPos += 6;
                                                                                                    } else {
                                                                                                      s3 = peg$FAILED;
                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$e59); }
                                                                                                    }
                                                                                                    if (s3 !== peg$FAILED) {
                                                                                                      peg$savedPos = s2;
                                                                                                      s3 = peg$f63(s3);
                                                                                                    }
                                                                                                    s2 = s3;
                                                                                                    if (s2 !== peg$FAILED) {
                                                                                                      s3 = peg$parseQuotedString();
                                                                                                      if (s3 === peg$FAILED) {
                                                                                                        s3 = peg$parseIntBareWord();
                                                                                                        if (s3 === peg$FAILED) {
                                                                                                          s3 = peg$parseEmpty();
                                                                                                        }
                                                                                                      }
                                                                                                      if (s3 !== peg$FAILED) {
                                                                                                        s1 = [ s2, s3 ];
                                                                                                      } else {
                                                                                                        peg$currPos = s1;
                                                                                                        s1 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s1;
                                                                                                      s1 = peg$FAILED;
                                                                                                    }
                                                                                                    if (s1 === peg$FAILED) {
                                                                                                      s1 = peg$currPos;
                                                                                                      s2 = peg$currPos;
                                                                                                      if (input.substr(peg$currPos, 6).toLowerCase() === peg$c60) {
                                                                                                        s3 = input.substr(peg$currPos, 6);
                                                                                                        peg$currPos += 6;
                                                                                                      } else {
                                                                                                        s3 = peg$FAILED;
                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$e60); }
                                                                                                      }
                                                                                                      if (s3 !== peg$FAILED) {
                                                                                                        peg$savedPos = s2;
                                                                                                        s3 = peg$f64(s3);
                                                                                                      }
                                                                                                      s2 = s3;
                                                                                                      if (s2 !== peg$FAILED) {
                                                                                                        s3 = peg$parseQuotedString();
                                                                                                        if (s3 === peg$FAILED) {
                                                                                                          s3 = peg$parseIntBareWord();
                                                                                                          if (s3 === peg$FAILED) {
                                                                                                            s3 = peg$parseEmpty();
                                                                                                          }
                                                                                                        }
                                                                                                        if (s3 !== peg$FAILED) {
                                                                                                          s1 = [ s2, s3 ];
                                                                                                        } else {
                                                                                                          peg$currPos = s1;
                                                                                                          s1 = peg$FAILED;
                                                                                                        }
                                                                                                      } else {
                                                                                                        peg$currPos = s1;
                                                                                                        s1 = peg$FAILED;
                                                                                                      }
                                                                                                      if (s1 === peg$FAILED) {
                                                                                                        s1 = peg$currPos;
                                                                                                        s2 = peg$currPos;
                                                                                                        if (input.substr(peg$currPos, 6).toLowerCase() === peg$c61) {
                                                                                                          s3 = input.substr(peg$currPos, 6);
                                                                                                          peg$currPos += 6;
                                                                                                        } else {
                                                                                                          s3 = peg$FAILED;
                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$e61); }
                                                                                                        }
                                                                                                        if (s3 !== peg$FAILED) {
                                                                                                          peg$savedPos = s2;
                                                                                                          s3 = peg$f65(s3);
                                                                                                        }
                                                                                                        s2 = s3;
                                                                                                        if (s2 !== peg$FAILED) {
                                                                                                          s3 = peg$parseQuotedString();
                                                                                                          if (s3 === peg$FAILED) {
                                                                                                            s3 = peg$parseIntBareWord();
                                                                                                            if (s3 === peg$FAILED) {
                                                                                                              s3 = peg$parseEmpty();
                                                                                                            }
                                                                                                          }
                                                                                                          if (s3 !== peg$FAILED) {
                                                                                                            s1 = [ s2, s3 ];
                                                                                                          } else {
                                                                                                            peg$currPos = s1;
                                                                                                            s1 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s1;
                                                                                                          s1 = peg$FAILED;
                                                                                                        }
                                                                                                        if (s1 === peg$FAILED) {
                                                                                                          s1 = peg$currPos;
                                                                                                          s2 = peg$currPos;
                                                                                                          if (input.substr(peg$currPos, 4).toLowerCase() === peg$c62) {
                                                                                                            s3 = input.substr(peg$currPos, 4);
                                                                                                            peg$currPos += 4;
                                                                                                          } else {
                                                                                                            s3 = peg$FAILED;
                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$e62); }
                                                                                                          }
                                                                                                          if (s3 !== peg$FAILED) {
                                                                                                            peg$savedPos = s2;
                                                                                                            s3 = peg$f66(s3);
                                                                                                          }
                                                                                                          s2 = s3;
                                                                                                          if (s2 !== peg$FAILED) {
                                                                                                            s3 = peg$parseQuotedString();
                                                                                                            if (s3 === peg$FAILED) {
                                                                                                              s3 = peg$parseIntBareWord();
                                                                                                              if (s3 === peg$FAILED) {
                                                                                                                s3 = peg$parseEmpty();
                                                                                                              }
                                                                                                            }
                                                                                                            if (s3 !== peg$FAILED) {
                                                                                                              s1 = [ s2, s3 ];
                                                                                                            } else {
                                                                                                              peg$currPos = s1;
                                                                                                              s1 = peg$FAILED;
                                                                                                            }
                                                                                                          } else {
                                                                                                            peg$currPos = s1;
                                                                                                            s1 = peg$FAILED;
                                                                                                          }
                                                                                                          if (s1 === peg$FAILED) {
                                                                                                            s1 = peg$currPos;
                                                                                                            s2 = peg$currPos;
                                                                                                            if (input.substr(peg$currPos, 5).toLowerCase() === peg$c63) {
                                                                                                              s3 = input.substr(peg$currPos, 5);
                                                                                                              peg$currPos += 5;
                                                                                                            } else {
                                                                                                              s3 = peg$FAILED;
                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$e63); }
                                                                                                            }
                                                                                                            if (s3 !== peg$FAILED) {
                                                                                                              peg$savedPos = s2;
                                                                                                              s3 = peg$f67(s3);
                                                                                                            }
                                                                                                            s2 = s3;
                                                                                                            if (s2 !== peg$FAILED) {
                                                                                                              s3 = peg$parseQuotedString();
                                                                                                              if (s3 === peg$FAILED) {
                                                                                                                s3 = peg$parseIntBareWord();
                                                                                                                if (s3 === peg$FAILED) {
                                                                                                                  s3 = peg$parseEmpty();
                                                                                                                }
                                                                                                              }
                                                                                                              if (s3 !== peg$FAILED) {
                                                                                                                s1 = [ s2, s3 ];
                                                                                                              } else {
                                                                                                                peg$currPos = s1;
                                                                                                                s1 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s1;
                                                                                                              s1 = peg$FAILED;
                                                                                                            }
                                                                                                            if (s1 === peg$FAILED) {
                                                                                                              s1 = peg$currPos;
                                                                                                              s2 = peg$currPos;
                                                                                                              if (input.substr(peg$currPos, 7).toLowerCase() === peg$c64) {
                                                                                                                s3 = input.substr(peg$currPos, 7);
                                                                                                                peg$currPos += 7;
                                                                                                              } else {
                                                                                                                s3 = peg$FAILED;
                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$e64); }
                                                                                                              }
                                                                                                              if (s3 !== peg$FAILED) {
                                                                                                                peg$savedPos = s2;
                                                                                                                s3 = peg$f68(s3);
                                                                                                              }
                                                                                                              s2 = s3;
                                                                                                              if (s2 !== peg$FAILED) {
                                                                                                                s3 = peg$parseQuotedString();
                                                                                                                if (s3 === peg$FAILED) {
                                                                                                                  s3 = peg$parseIntBareWord();
                                                                                                                  if (s3 === peg$FAILED) {
                                                                                                                    s3 = peg$parseEmpty();
                                                                                                                  }
                                                                                                                }
                                                                                                                if (s3 !== peg$FAILED) {
                                                                                                                  s1 = [ s2, s3 ];
                                                                                                                } else {
                                                                                                                  peg$currPos = s1;
                                                                                                                  s1 = peg$FAILED;
                                                                                                                }
                                                                                                              } else {
                                                                                                                peg$currPos = s1;
                                                                                                                s1 = peg$FAILED;
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f69(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseEmptyParens() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 40) {
      s1 = peg$c0;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e0); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseWhitespace();
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parseWhitespace();
      }
      if (input.charCodeAt(peg$currPos) === 41) {
        s3 = peg$c1;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e1); }
      }
      if (s3 !== peg$FAILED) {
        s1 = [s1, s2, s3];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseWhitespace() {
    var s0;

    if (peg$r0.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e65); }
    }

    return s0;
  }

  function peg$parseBareWord() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    s3 = peg$currPos;
    peg$silentFails++;
    s4 = peg$parseWhitespace();
    peg$silentFails--;
    if (s4 === peg$FAILED) {
      s3 = undefined;
    } else {
      peg$currPos = s3;
      s3 = peg$FAILED;
    }
    if (s3 !== peg$FAILED) {
      if (input.length > peg$currPos) {
        s4 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e66); }
      }
      if (s4 !== peg$FAILED) {
        s3 = [s3, s4];
        s2 = s3;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$currPos;
        s3 = peg$currPos;
        peg$silentFails++;
        s4 = peg$parseWhitespace();
        peg$silentFails--;
        if (s4 === peg$FAILED) {
          s3 = undefined;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          if (input.length > peg$currPos) {
            s4 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e66); }
          }
          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f70();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseIntBareWord() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    s3 = peg$currPos;
    peg$silentFails++;
    s4 = peg$parseWhitespace();
    peg$silentFails--;
    if (s4 === peg$FAILED) {
      s3 = undefined;
    } else {
      peg$currPos = s3;
      s3 = peg$FAILED;
    }
    if (s3 !== peg$FAILED) {
      s4 = peg$currPos;
      peg$silentFails++;
      if (input.charCodeAt(peg$currPos) === 41) {
        s5 = peg$c1;
        peg$currPos++;
      } else {
        s5 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e1); }
      }
      peg$silentFails--;
      if (s5 === peg$FAILED) {
        s4 = undefined;
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
      if (s4 !== peg$FAILED) {
        if (input.length > peg$currPos) {
          s5 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e66); }
        }
        if (s5 !== peg$FAILED) {
          s3 = [s3, s4, s5];
          s2 = s3;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$currPos;
        s3 = peg$currPos;
        peg$silentFails++;
        s4 = peg$parseWhitespace();
        peg$silentFails--;
        if (s4 === peg$FAILED) {
          s3 = undefined;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          peg$silentFails++;
          if (input.charCodeAt(peg$currPos) === 41) {
            s5 = peg$c1;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e1); }
          }
          peg$silentFails--;
          if (s5 === peg$FAILED) {
            s4 = undefined;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            if (input.length > peg$currPos) {
              s5 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e66); }
            }
            if (s5 !== peg$FAILED) {
              s3 = [s3, s4, s5];
              s2 = s3;
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f71();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseSExpression() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    s3 = peg$currPos;
    peg$silentFails++;
    if (input.charCodeAt(peg$currPos) === 34) {
      s4 = peg$c65;
      peg$currPos++;
    } else {
      s4 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e67); }
    }
    if (s4 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 40) {
        s4 = peg$c0;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e0); }
      }
      if (s4 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c66;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e68); }
        }
        if (s4 === peg$FAILED) {
          s4 = peg$parseWhitespace();
        }
      }
    }
    peg$silentFails--;
    if (s4 === peg$FAILED) {
      s3 = undefined;
    } else {
      peg$currPos = s3;
      s3 = peg$FAILED;
    }
    if (s3 !== peg$FAILED) {
      if (input.length > peg$currPos) {
        s4 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e66); }
      }
      if (s4 !== peg$FAILED) {
        s3 = [s3, s4];
        s2 = s3;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$currPos;
        s3 = peg$currPos;
        peg$silentFails++;
        if (input.charCodeAt(peg$currPos) === 34) {
          s4 = peg$c65;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e67); }
        }
        if (s4 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 40) {
            s4 = peg$c0;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e0); }
          }
          if (s4 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 47) {
              s4 = peg$c66;
              peg$currPos++;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e68); }
            }
            if (s4 === peg$FAILED) {
              s4 = peg$parseWhitespace();
            }
          }
        }
        peg$silentFails--;
        if (s4 === peg$FAILED) {
          s3 = undefined;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          if (input.length > peg$currPos) {
            s4 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e66); }
          }
          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 40) {
        s2 = peg$c0;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e0); }
      }
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$currPos;
        s5 = peg$currPos;
        peg$silentFails++;
        if (input.charCodeAt(peg$currPos) === 41) {
          s6 = peg$c1;
          peg$currPos++;
        } else {
          s6 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e1); }
        }
        peg$silentFails--;
        if (s6 === peg$FAILED) {
          s5 = undefined;
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        if (s5 !== peg$FAILED) {
          if (input.length > peg$currPos) {
            s6 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s6 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e66); }
          }
          if (s6 !== peg$FAILED) {
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$currPos;
            s5 = peg$currPos;
            peg$silentFails++;
            if (input.charCodeAt(peg$currPos) === 41) {
              s6 = peg$c1;
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e1); }
            }
            peg$silentFails--;
            if (s6 === peg$FAILED) {
              s5 = undefined;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              if (input.length > peg$currPos) {
                s6 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$e66); }
              }
              if (s6 !== peg$FAILED) {
                s5 = [s5, s6];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 41) {
            s4 = peg$c1;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e1); }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f72();
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseRegex() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 47) {
      s1 = peg$c66;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e68); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      if (input.substr(peg$currPos, 2) === peg$c67) {
        s3 = peg$c67;
        peg$currPos += 2;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e69); }
      }
      if (s3 === peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$currPos;
        peg$silentFails++;
        if (input.charCodeAt(peg$currPos) === 47) {
          s5 = peg$c66;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e68); }
        }
        peg$silentFails--;
        if (s5 === peg$FAILED) {
          s4 = undefined;
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          if (input.length > peg$currPos) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e66); }
          }
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          if (input.substr(peg$currPos, 2) === peg$c67) {
            s3 = peg$c67;
            peg$currPos += 2;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e69); }
          }
          if (s3 === peg$FAILED) {
            s3 = peg$currPos;
            s4 = peg$currPos;
            peg$silentFails++;
            if (input.charCodeAt(peg$currPos) === 47) {
              s5 = peg$c66;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e68); }
            }
            peg$silentFails--;
            if (s5 === peg$FAILED) {
              s4 = undefined;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
            if (s4 !== peg$FAILED) {
              if (input.length > peg$currPos) {
                s5 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$e66); }
              }
              if (s5 !== peg$FAILED) {
                s4 = [s4, s5];
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          }
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 47) {
          s3 = peg$c66;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e68); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          peg$silentFails++;
          if (input.charCodeAt(peg$currPos) === 41) {
            s5 = peg$c1;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e1); }
          }
          if (s5 === peg$FAILED) {
            s5 = peg$parseFINISH();
          }
          peg$silentFails--;
          if (s5 !== peg$FAILED) {
            peg$currPos = s4;
            s4 = undefined;
          } else {
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f73(s2);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseQuotedString() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 34) {
      s1 = peg$c65;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e67); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.substr(peg$currPos, 2) === peg$c68) {
        s4 = peg$c68;
        peg$currPos += 2;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e70); }
      }
      if (s4 !== peg$FAILED) {
        s3 = s4;
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      if (s3 === peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$currPos;
        peg$silentFails++;
        if (input.charCodeAt(peg$currPos) === 34) {
          s5 = peg$c65;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e67); }
        }
        peg$silentFails--;
        if (s5 === peg$FAILED) {
          s4 = undefined;
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          if (input.length > peg$currPos) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e66); }
          }
          if (s5 !== peg$FAILED) {
            s3 = s5;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.substr(peg$currPos, 2) === peg$c68) {
          s4 = peg$c68;
          peg$currPos += 2;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e70); }
        }
        if (s4 !== peg$FAILED) {
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = peg$currPos;
          s4 = peg$currPos;
          peg$silentFails++;
          if (input.charCodeAt(peg$currPos) === 34) {
            s5 = peg$c65;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e67); }
          }
          peg$silentFails--;
          if (s5 === peg$FAILED) {
            s4 = undefined;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            if (input.length > peg$currPos) {
              s5 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e66); }
            }
            if (s5 !== peg$FAILED) {
              s3 = s5;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
      }
      if (input.charCodeAt(peg$currPos) === 34) {
        s3 = peg$c65;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e67); }
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$currPos;
        peg$silentFails++;
        if (input.charCodeAt(peg$currPos) === 41) {
          s5 = peg$c1;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e1); }
        }
        if (s5 === peg$FAILED) {
          s5 = peg$parseFINISH();
        }
        peg$silentFails--;
        if (s5 !== peg$FAILED) {
          peg$currPos = s4;
          s4 = undefined;
        } else {
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f74(s2);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseUnsignedInteger() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    if (peg$r1.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e71); }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        if (peg$r1.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e71); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f75();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseEmpty() {
    var s0, s1;

    s0 = peg$currPos;
    peg$silentFails++;
    s1 = peg$parseFINISH();
    peg$silentFails--;
    if (s1 !== peg$FAILED) {
      peg$currPos = s0;
      s0 = undefined;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFINISH() {
    var s0, s1;

    s0 = peg$parseWhitespace();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      peg$silentFails++;
      if (input.length > peg$currPos) {
        s1 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e66); }
      }
      peg$silentFails--;
      if (s1 === peg$FAILED) {
        s0 = undefined;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseEND() {
    var s0, s1;

    s0 = peg$currPos;
    peg$silentFails++;
    if (input.length > peg$currPos) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e66); }
    }
    peg$silentFails--;
    if (s1 === peg$FAILED) {
      s0 = undefined;
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  peg$result = peg$startRuleFunction();

  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail(peg$endExpectation());
    }

    throw peg$buildStructuredError(
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

export {
  peg$SyntaxError as SyntaxError,

  peg$parse as parse
};
